import { withStyles } from "@material-ui/core";
import React, { useState } from "react";
import Dialog from "../../../../../../components/Dialog/Dialog";
import MultiToggleButtons from "../../../../../../components/Button/MultiToggleButtons";
import { tabs } from "../../constants";
import TabOrcamento from "./TabOrcamento";
import { Button } from "../../../../../../components/ui/Buttons";
import CloseIcon from "../../../../../../components/Icon/CloseIcon";
import TabHistoricoAgendamentos from "./TabHistoricoAgendamentos";

const Modal = ({
  classes,
  open,
  onClose,
  procedimentoOdontologicoSelected,
  orcamentoSelected,
}) => {
  const [tabSelected, setTabSelected] = useState(tabs.ORCAMENTO);

  const changeTabSelected = (tabSelected) => {
    setTabSelected(tabSelected);
  };

  const render = () => {
    return tabSelected === tabs.ORCAMENTO ? (
      <TabOrcamento orcamentoSelected={orcamentoSelected} />
    ) : (
      <TabHistoricoAgendamentos
        procedimentoOdontologicoSelected={procedimentoOdontologicoSelected}
        orcamentoSelected={orcamentoSelected}
      />
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
      maxWidth="md"
      fullWidth
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Button onClick={onClose} bgColor="#FB7676" shape="circle">
            <CloseIcon />
          </Button>
        </div>
        <MultiToggleButtons
          options={["Orçamento", "Histórico agendamentos"]}
          tabSelected={tabSelected}
          changeTabSelected={changeTabSelected}
        />
        {render()}
      </div>
    </Dialog>
  );
};

const styles = {
  dialogPaper: {
    height: "500px",
    width: "630px",
    padding: "16px",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
    height: "100%",
  },
};

export default withStyles(styles)(Modal);
