import { Tooltip, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { sujeitoAtencaoOrcamentoHeader } from "../../../../../../../../services/OrcamentoService";
import moment from "moment";
import { Button } from "../../../../../../../../components/ui/Buttons";
import OrcamentoEmitidoIcon from "../../../../../../../../components/Icon/OrcamentoEmitidoIcon";
import { withRouter } from "react-router-dom";
import { TextFieldSearch } from "../../../../../../../../components/TextField";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { findAllStatusOrcamentoOdontologicoSelect } from "../../../../../../../../services/StatusOrcamentoService";

const dadosSujeitoAtencaoDefault = {
  nome: "",
  contato: {
    telefonePrincipal: "",
    email: "",
  },
};
const HeaderOdontologico = observer(
  ({ classes, sujeitoAtencao, orcamento, history, orcamentoStore }) => {
    const [dadosSujeitoAtencao, setDadosSujeitoAtencao] = useState(
      dadosSujeitoAtencaoDefault
    );

    const { nome, contato } = dadosSujeitoAtencao || {};
    const {
      statusOrcamentoOdontologico,
      dataHoraLancamento,
      id: orcamentoId,
      numero,
    } = orcamento || {};
    const { id } = sujeitoAtencao || {};

    const isGerenciadorOrcamento = history.location.pathname.includes(
      "gerenciador-orcamentos"
    );

    useEffect(() => {
      sujeitoAtencao?.id && getDadosSujeitoAtencao();
    }, [sujeitoAtencao]);

    const getDadosSujeitoAtencao = async () => {
      try {
        const response = await sujeitoAtencaoOrcamentoHeader(id);
        setDadosSujeitoAtencao(response);
      } catch (error) {
        console.error(error);
      }
    };

    const loadStatusOrcamento = async (search, loadedOptions, { page }) => {
      const pageableDTO = {
        pageNumber: page,
        pageSize: 10,
        sortDir: "ASC",
        sortField: "nome",
      };
      const variables = {
        pageableDTO,
        search,
        ativo: true,
      };

      try {
        const {
          content,
          last,
        } = await findAllStatusOrcamentoOdontologicoSelect(variables);
        return {
          options: content,
          hasMore: !last,
          additional: {
            page: page + 1,
          },
        };
      } catch (error) {
        console.error(error);
      }
    };

    const handleStatus = (status) => {
      orcamentoStore.handleFieldModal("statusOrcamentoOdontologico", status);
    };

    return (
      <div className={classes.container}>
        <div className={classes.orcamentoNome}>
          <div className={classes.nomeSujeito}>
            <h2>{nome}</h2>
            <Tooltip title="Ir para orçamentos">
              <Button
                shape="circle"
                bgColor="#219A97"
                onClick={() =>
                  history.push(`/sujeito-atencao/edit/${id}/orcamentos`)
                }
                padding={0}
                style={{ height: 24, width: 24, display: "flex" }}
              >
                <OrcamentoEmitidoIcon />
              </Button>
            </Tooltip>
          </div>
          {orcamentoId && (
            <div className={classes.field}>
              <p className={classes.nameField}>Nº:</p>
              <p>{numero}</p>
            </div>
          )}
        </div>
        <div className={classes.row}>
          <div className={classes.field}>
            <p className={classes.nameField}>Telefone:</p>
            <p>{contato?.telefonePrincipal}</p>
          </div>
          <div className={classes.field}>
            <p className={classes.nameField}>E-mail:</p>
            <p>{contato?.email}</p>
          </div>
        </div>
        <div className={classes.row}>
          {isGerenciadorOrcamento ? (
            <div className={classes.fieldStatus}>
              <TextFieldSearch
                placeholder="Status"
                classIcons={classes.classIcons}
                classInput={classes.reactSelect}
                classNotched={classes.notchedOutline}
                loadOptions={loadStatusOrcamento}
                getOptionLabel={(option) => option?.nome}
                getOptionValue={(option) => option?.id}
                value={statusOrcamentoOdontologico}
                classes={{ paper: classes.menuHeight }}
                onChange={handleStatus}
                withPaginate
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
                menuPosition="fixed"
              />
            </div>
          ) : (
            <div className={classes.field}>
              <p className={classes.nameField}>Status:</p>
              <p>{statusOrcamentoOdontologico?.nome || ""}</p>
            </div>
          )}
          {orcamentoId && (
            <div className={classes.field}>
              <p className={classes.nameField}>Lançamento:</p>
              <p>{moment(dataHoraLancamento).format("DD/MM/YYYY HH:mm")}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
);

const style = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  orcamentoNome: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h2": {
      margin: 0,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  },
  field: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
    "& > p": {
      margin: 0,
    },
  },
  fieldStatus: {
    width: "50%",
  },
  nameField: {
    fontSize: "12px",
    color: "#505050",
  },
  nomeSujeito: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
};

const HeaderOdontologicoWithStylesRouter = withRouter(
  withStyles(style)(HeaderOdontologico)
);
export default inject("orcamentoStore")(HeaderOdontologicoWithStylesRouter);
