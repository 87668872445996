import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./StatusOrcamentoStyle";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import HeaderConfiguracoesButtonNovo from "../Components/HeaderConfiguracoesButtonNovo";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import Table from "../../../components/Table/Table";
import ModalStatusOrcamento from "./ModalStatusOrcamento";
import Notification from "../../../components/Notification";
import MensagemListaVazia from "../../../components/Mensagem/MensagemListaVazia";
import {
  notificationDefault,
  pageableDTODefault,
  statusDefault,
  columns,
  tipos,
} from "./constants";
import {
  findAllStatusOrcamentoOdontologico,
  ativarStatusOrcamentoOdontologico,
  inativarStatusOrcamentoOdontologico,
  findStatusOrcamentoOdontologicoById,
} from "../../../services/StatusOrcamentoService";

const StatusOrcamento = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [last, setLast] = useState(true);
  const [pageableDTO, setPageableDTO] = useState(pageableDTODefault);
  const [search, setSearch] = useState("");
  const [statusOrcamento, setStatusOrcamento] = useState([]);
  const [notification, setNotification] = useState(notificationDefault);
  const [statusSelecionado, setStatusSelecionado] = useState(statusDefault);

  useEffect(() => {
    loadStatusOrcamento({ isClear: true });
  }, [pageableDTO.sortDir, pageableDTO.sortField]);

  const handleCreate = () => {
    setStatusSelecionado(statusDefault);
    setOpen(true);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const onKeypressSearch = (e) => {
    if (e.key === "Enter") {
      handleClickSearch();
    }
  };

  const handleClickSearch = () => {
    setPageableDTO({ ...pageableDTO, pageNumber: 0 });
    loadStatusOrcamento({ isClear: true });
  };

  const handleClickClear = () => {
    setSearch("");
    setPageableDTO({ ...pageableDTO, pageNumber: 0 });
    loadStatusOrcamento({ isClear: true, isClearSearching: true });
  };

  const openNotification = ({ message, type }) => {
    setNotification({
      open: true,
      type,
      message,
    });

    const timeoutId = setTimeout(() => {
      resetNotification();
      clearTimeout(timeoutId);
    }, 4000);
  };

  const resetNotification = () => {
    setNotification(notificationDefault);
  };

  const closeAlertMessage = () => {
    setNotification(notificationDefault);
  };

  const loadStatusOrcamento = async (options) => {
    try {
      setLoading(true);

      const pageable = {
        ...pageableDTO,
        pageNumber: options?.isClear ? 0 : pageableDTO.pageNumber,
      };

      const textSearch = options?.isClearSearching ? "" : search;

      const response = await findAllStatusOrcamentoOdontologico({
        search: textSearch,
        pageableDTO: pageable,
      });

      const { content, last } = response;
      const pageNumber = options?.isClear ? 0 : pageable.pageNumber + 1;

      setStatusOrcamento(
        options?.isClear ? content : [...statusOrcamento, ...content]
      );
      setLast(last);
      setPageableDTO({ ...pageableDTO, pageNumber });
    } catch (e) {
      openNotification({
        message: "Erro ao carregar os status",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClickOrdenar = (value) => {
    const sortDir =
      pageableDTO.sortField !== value
        ? "ASC"
        : pageableDTO.sortDir === "ASC"
        ? "DESC"
        : "ASC";
    setPageableDTO({
      ...pageableDTO,
      pageNumber: 0,
      sortDir: sortDir,
      sortField: value,
    });
  };

  const handleSelectStatusOrcamento = async (statusId) => {
    const response = await findStatusOrcamentoOdontologicoById({
      statusOrcamentoOdontologicoId: statusId,
    });

    setStatusSelecionado({
      ...response,
      tipo: tipos.find((item) => item.value === response?.tipo),
    });
    setOpen(true);
  };

  const handleDuplicar = async (statusId) => {
    const response = await findStatusOrcamentoOdontologicoById({
      statusOrcamentoOdontologicoId: statusId,
    });

    setStatusSelecionado({
      ...response,
      tipo: tipos.find((item) => item.value === response?.tipo),
      id: null,
    });
    setOpen(true);
  };

  const handleDesativar = async (id) => {
    try {
      await inativarStatusOrcamentoOdontologico(id);
      loadStatusOrcamento({ isClear: true });
    } catch (e) {
      openNotification({
        message: "Erro ao inativar o status",
        type: "error",
      });
    }
  };

  const handleAtivar = async (id) => {
    try {
      await ativarStatusOrcamentoOdontologico(id);
      loadStatusOrcamento({ isClear: true });
    } catch (e) {
      openNotification({
        message: "Erro ao inativar o status",
        type: "error",
      });
    }
  };

  return (
    <div className={classes.container}>
      <PageTitle title={pageConfiguracoes("Status orçamento")} />
      <HeaderConfiguracoesButtonNovo
        title="Status orçamento"
        labelButton="Novo status"
        handleClickNovo={handleCreate}
        search={{
          handleChange: handleSearchChange,
          value: search,
          onKeypress: onKeypressSearch,
          handleClickClear: handleClickClear,
          onClickButtonSearch: handleClickSearch,
        }}
      />
      <div className={classes.tableContainer}>
        <Scroll
          loadMore={() => loadStatusOrcamento()}
          hasMore={last}
          pageStart={0}
          initialLoad={false}
          className={classes.scrollContainer}
          isLoading={loading}
        >
          <Table
            dados={statusOrcamento}
            columns={columns({
              functions: {
                handleEditar: handleSelectStatusOrcamento,
                handleDuplicar,
                handleDesativar,
                handleAtivar,
              },
            })}
            clickable={true}
            handleClick={handleSelectStatusOrcamento}
            ordenarTabela={{
              sortField: pageableDTO.sortField,
              sortDir: pageableDTO.sortDir,
            }}
            comOrdenacao
            handleClickOrdenar={handleClickOrdenar}
          />
          {statusOrcamento?.length === 0 && !loading && (
            <div className={classes.notFoundContainer}>
              <MensagemListaVazia />
            </div>
          )}
        </Scroll>
      </div>
      {open && (
        <ModalStatusOrcamento
          open={open}
          onClose={() => setOpen(false)}
          statusSelecionado={statusSelecionado}
          loadStatusOrcamento={loadStatusOrcamento}
          openNotification={openNotification}
        />
      )}
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.open}
        variant={notification.type}
        message={notification.message}
      />
    </div>
  );
};

export default withStyles(styles)(StatusOrcamento);
