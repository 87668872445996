export const getColumns = () => {

  const columns = [
    {
      Header: 'Profissional',
      align: 'start',
      getValue: data => {
        return data.profissional
      },
    },
    {
      Header: 'Avaliação',
      align: 'start',
      getValue: data => {
        return data?.avaliacao
      },
    },

  ]
  return columns

}

export const pageableDTODefault = {
  pageNumber: 0,
  pageSize: 30,
  sortField: "profissional",
  sortDir: "ASC",
};