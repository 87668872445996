import { withStyles } from "@material-ui/core";
import React from "react";
import { ReactSelect } from "../../../../../components/Select/SelectSearch";
import { procedimentoRealizadoOptions, statusOptions } from "../constants";
import { Button } from "../../../../../components/ui/Buttons";

const Filters = ({
  classes,
  filters,
  handleFilterChange,
  loadProcedimentosOdontologico,
}) => {
  const { procedimentoRealizado, status } = filters;

  const handleFiltrar = () => {
    loadProcedimentosOdontologico({ isClearable: true });
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.filter}>
          <label>Realizado</label>
          <ReactSelect
            className={classes.selectSearch}
            placeholder={"Selecione"}
            value={procedimentoRealizado}
            onChange={(e) => handleFilterChange(e, "procedimentoRealizado")}
            options={procedimentoRealizadoOptions}
            isClearable
          />
        </div>
        <div className={classes.filter}>
          <label>Status</label>
          <ReactSelect
            className={classes.selectSearch}
            placeholder={"Selecione"}
            value={status}
            onChange={(e) => handleFilterChange(e, "status")}
            options={statusOptions}
            isClearable
          />
        </div>
      </div>
      <Button
        onClick={handleFiltrar}
        style={{ height: "40px" }}
        bgColor="#707C97"
      >
        Filtrar
      </Button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    background: "#fff",
    padding: "16px",
    borderRadius: "16px",
    justifyContent: "space-between",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    alignItems: "center",
  },
  filter: {
    display: "flex",
    flexDirection: "column",
    width: "140px",

    "& > label": {
      fontSize: "12px",
      fontWeight: "400",
      color: "#868686",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
  },
  selectSearch: {
    width: "100%",
  },
};

export default withStyles(styles)(Filters);
