import moment from "moment";
import React, { useRef } from "react";
import { concatNomelocal } from "../../../Atendimento/Tabs/Odontograma/Modal/Orcamento/Constatnts";
import PopperCustomMenu from "../../../../components/Popper/PopperCustomMenu";
import MoreIcon from "../../../../components/Icon/More";
import CloseIcon from "../../../../components/Icon/Close";
import SuccessIcon from "../../../../components/Icon/SuccessIcon";
import string from "../../../../utils/string";
import ArrowCounterIcon from "../../../../components/Icon/ArrowCounterIcon";

export const filtersDefault = {
  procedimentoRealizado: null,
  status: null,
};

export const pageableDTODefault = {
  pageNumber: 0,
  pageSize: 30,
  sortDir: "ASC",
  sortField: "dataHoraLancamento",
};

export const procedimentoRealizadoOptions = [
  {
    value: true,
    label: "Sim",
  },
  {
    value: false,
    label: "Nao",
  },
];

export const statusOptions = [
  {
    value: "PENDENTE_APROVACAO",
    label: "Pendente",
  },
  {
    value: "APROVADO",
    label: "Aprovado",
  },
  {
    value: "REPROVADO",
    label: "Reprovado",
  },
  {
    value: "FINALIZADO",
    label: "Finalizado",
  },
  {
    value: "EM_ANDAMENTO",
    label: "Em andamento",
  },
  {
    value: "CANCELAR_EXECUCAO",
    label: "Cancelado",
  },
];

export const columns = ({ functions }) => {
  return [
    {
      Header: "Nome",
      align: "start",
      getValue: (data) => {
        return data.procedimento.nome;
      },
    },
    {
      Header: "Status",
      align: "start",
      getValue: (data) => {
        const status = statusOptions.find((item) => item.value === data.status);

        return status.label;
      },
    },
    {
      Header: "Valor",
      align: "start",
      getValue: (data) => {
        return data.valor;
      },
    },
    {
      Header: "Data finalização",
      align: "start",
      getValue: (data) => {
        const { agendamento } = data || {};
        return agendamento ? moment(agendamento.data).format("DD/MM/YYYY") : "";
      },
    },
    {
      Header: "Faturado",
      align: "start",
      getValue: (data) => {
        return data.faturado ? "Sim" : "Não";
      },
    },
    {
      Header: "Profissional",
      align: "start",
      getValue: (data) => {
        const { agendamento } = data || {};
        return agendamento?.profissionalSaude?.nome || "";
      },
    },
    {
      Header: "Ação",
      align: "start",
      isClick: true,
      getValue: (data) => {
        return <PopperMenu row={data} functions={functions} />;
      },
    },
  ];
};

export const procedimentoOdontologicoSelectedDefault = {
  id: null,
  numero: "",
  observacao: "",
  status: "",
  statusOrcamentoOdontologico: null,
  convenio: null,
  procedimentosOdontologicos: [],
};

export const notificationDefault = {
  open: false,
  message: "",
  type: "",
};

export const tabs = {
  ORCAMENTO: 0,
  HISTORICO_AGENDAMENTO: 1,
};

export const columnsModal = [
  {
    Header: "Nome",
    align: "start",
    getValue: (data) => {
      return data.procedimento.nome;
    },
  },
  {
    Header: "Local",
    align: "start",
    getValue: (data) => {
      const face = data.face;
      const local = data.dente ? data.dente.codigo : data.mucosaOral?.descricao;
      return concatNomelocal(face, local);
    },
  },
  {
    Header: "Status",
    align: "start",
    getValue: (data) => {
      return statusOptions[data.status];
    },
  },
];

const PopperMenu = ({ row, functions }) => {
  const ref = useRef(null);

  const handleCloseWithAction = (fn) => {
    return () => {
      ref?.current && ref.current.closePopper();
      fn(row);
    };
  };

  return (
    <PopperCustomMenu
      menuOpcoes={menuOpcoes({
        row: row,
        functions: {
          handleFinalizar: handleCloseWithAction(functions?.handleFinalizar),
          handleCancelar: handleCloseWithAction(functions?.handleCancelar),
          handleEmAndamento: handleCloseWithAction(functions?.handleEmAndamento),
        },
        isEdit: true,
      })}
      ref={ref}
      iconButton={<MoreIcon color="#505050" />}
      colorButton={{
        background: "#fff",
      }}
    />
  );
};
const menuOpcoes = ({ row, functions }) => {
  const handleCallCurrentFunction = ({ functionName, ...params }) => {
    if (functionName) {
      functionName(row);
      if (
        functions?.closePopper &&
        typeof functions?.closePopper === "function"
      ) {
        functions.closePopper();
      }
    }
  };

  const optionsMenu = [
    {
      ativo: true,
      icon: <SuccessIcon size="15" color="#505050" />,
      label: "Finalizar",
      onClick: (e) =>
        handleCallCurrentFunction({
          functionName: functions?.handleFinalizar,
          ...e,
        }),
    },
    {
      ativo: true,
      icon: <CloseIcon size="15" color="#505050" />,
      label: "Cancelar",
      onClick: (e) =>
        handleCallCurrentFunction({
          functionName: functions?.handleCancelar,
          ...e,
        }),
    },
    {
      ativo: true,
      icon: <ArrowCounterIcon size="15" color="#505050" />,
      label: "Em andamento",
      onClick: (e) =>
        handleCallCurrentFunction({
          functionName: functions?.handleEmAndamento,
          ...e,
        }),
    },
  ];
  return optionsMenu;
};

export const columnsHistoricoAgendamentos = [
  {
    Header: "Data",
    align: "start",
    getValue: (data) => {
      return data.data;
    },
  },
  {
    Header: "Hora",
    align: "start",
    getValue: (data) => {
      return data.horaFormatada;
    },
  },
  {
    Header: "Situação",
    align: "start",
    getValue: (data) => {
      return data.situacao ? string.capitalize(data.situacao) : "";
    },
  },
  {
    Header: "Profissional",
    align: "start",
    getValue: (data) => {
      return data.profissionalSaude?.nome;
    },
  },
];
