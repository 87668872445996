import moment from "moment";

export const notificationDefault = {
  isOpen: false,
  message: "",
  variant: "",
};

export const filtersDefault = {
  statusOrcamentoOdontologico: null,
  numero: "",
  sujeitoAtencao: null,
  dataHoraLancamentoInicio: null,
  dataAgendamento: null,
  profissionalSaude: null,
};

export const columns = [
  {
    Header: "Número",
    align: "start",
    getValue: (data) => {
      return data.numero;
    },
  },
  {
    Header: "Paciente",
    align: "start",
    getValue: (data) => {
      return data.sujeitoAtencao?.nome || "";
    },
  },
  {
    Header: "Data agendamento",
    align: "start",
    getValue: (data) => {
      return data.agendamento?.id
        ? moment(data.agendamento?.data).format("DD/MM/YYYY")
        : "";
    },
  },
  {
    Header: "Data lançamento",
    align: "start",
    getValue: (data) => {
      return moment(data.dataHoraLancamento).format("DD/MM/YYYY HH:mm");
    },
  },
  {
    Header: "Profissional",
    align: "start",
    getValue: (data) => {
      return data.profissionalSaude?.nome || "";
    },
  },
  {
    Header: "Status",
    align: "start",
    getValue: (data) => {
      return data.statusOrcamentoOdontologico?.nome || "";
    },
  },
  {
    Header: "Último registro do histórico",
    align: "start",
    getValue: (data) => {
      const ultimoRegistro = data?.historico?.length > 0 && data?.historico[0];

      return ultimoRegistro ? ultimoRegistro.informacao : "";
    },
  },
];
