import moment from "moment"
import { applyCurrencyMask } from "../../../utils/CurrencyMask"

export const columns = [
  {
    Header: 'Data lançamento',
    align: 'start',
    getValue: (data) => {
      return moment(data.dataHoraLancamento).format('DD/MM/YYYY HH:mm')
    },
  },
  {
    Header: 'Número',
    align: 'start',
    getValue: (data) => {
      return data.numero
    },
  },
  {
    Header: 'Status',
    align: 'start',
    getValue: (data) => {
      return data.statusOrcamentoOdontologico?.nome || ""
    },
  },
  {
    Header: 'Valor',
    align: 'start',
    getValue: (data) => {
      return applyCurrencyMask(data.valor)
    },
  },
]