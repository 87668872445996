
export const columns = [
  {
    Header: "Nome",
    align: "left",
    getValue: (data) => {
      return data.nomePaciente;
    },
  },
  {
    Header: "Data",
    align: "left",
    getValue: (data) => {
      return data.dataAtendimento
    },
  },
  {
    Header: "Profissional",
    align: "left",
    getValue: (data) => {
      return data.nomeProfissionalSaude;
    },
  },
  {
    Header: "Convenio",
    align: "left",
    getValue: (data) => {
      return data.convenio;
    },

  },
  {
    Header: "Procedimento",
    align: "left",
    getValue: (data) => {
      return data.procedimento;
    },
    field: "procedimento",
  },

];

