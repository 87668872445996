import React, { useEffect } from "react";
import Profile from "../../template/Header/Profile";
import ItemGrid from "../../components/Modal/ItemGrid";
import InputSearchRetangular from "../../components/Input/InputSearchRetangular";
import { withStyles } from "@material-ui/core";
import { Button } from "../../components/ui/Buttons";
import { TextFieldSearch } from "../../components/TextField";
import { findAllProfissionalSaudeSelect } from "../../services/ProfissionalSaudeService";
import { findAllSujeitoAtencaoSelect } from "../../services/SujeitoAtencaoService";
import { InputDateForm } from "../../components/Modal/Input";
import { findAllStatusOrcamentoOdontologicoSelect, getStatusOrcamentoPendenteAprovacao } from "../../services/StatusOrcamentoService";

const HeaderFilters = ({
  classes,
  filters,
  setFilters,
  loadOrcamentos,
  loading,
}) => {

  useEffect(() => {
    getStatusPadrao();
  }, []);

  const getStatusPadrao = async () => {
    try {
      const response = await getStatusOrcamentoPendenteAprovacao() || {};
      setFilters({
        ...filters,
        statusOrcamentoOdontologico: response,
      });
    } catch {
      openNotification("Erro ao carregar ", "error");
    }
  };

  const handleChangeFilter = (value, field) => {
    setFilters({
      ...filters,
      [field]: value,
    });
  };

  const handleClickFilter = () => {
    loadOrcamentos({ isClear: true });
  };

  const loadSujeitosAtencao = async (search, loadedOptions, { page }) => {
    try {
      const searchDTO = {
        sortDir: "ASC",
        sortField: "nome",
        pageSize: 20,
        pageNumber: page,
        search,
        ativo: true,
      };

      const { content, last } =
        (await findAllSujeitoAtencaoSelect({ searchDTO })) || {};
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch {
      openNotification("Erro ao carregar ", "error");
    }
  };

  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    try {
      const searchDTO = {
        sortDir: "ASC",
        sortField: "nome",
        pageSize: 20,
        pageNumber: page,
        search,
        ativo: true,
      };

      const { content, last } = await findAllProfissionalSaudeSelect({
        searchDTO,
      });
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  const loadStatusOrcamentoOdontologico = async (
    search,
    loadedOptions,
    { page }
  ) => {
    try {
      const pageableDTO = {
        sortDir: "ASC",
        sortField: "nome",
        pageSize: 20,
        pageNumber: page,
      };

      const { content, last } = await findAllStatusOrcamentoOdontologicoSelect({
        pageableDTO,
        search,
        ativo: true,
      });
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.contentTitleHeader}>
        <div className={classes.titleHeader}> Gerenciador de Orçamentos </div>
        <Profile />
      </div>
      <div className={classes.contentFilters}>
        <ItemGrid label="Número" size={2}>
          <InputSearchRetangular
            type="number"
            hideStartAdornment
            placeholder="Pesquise pelo número"
            value={filters.numero}
            onChange={(e) => handleChangeFilter(e.target.value, "numero")}
            className={classes.search}
          />
        </ItemGrid>
        <ItemGrid label="Status" size={2}>
          <TextFieldSearch
            placeholder="Selecione"
            classInput={classes.reactSelect}
            classNotched={classes.notchedOutline}
            loadOptions={loadStatusOrcamentoOdontologico}
            getOptionLabel={(option) => option?.nome}
            getOptionValue={(option) => option?.id}
            value={filters?.statusOrcamentoOdontologico}
            onChange={(e) =>
              handleChangeFilter(e, "statusOrcamentoOdontologico")
            }
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </ItemGrid>
        <ItemGrid label="Data emissão" size={2}>
          <InputDateForm
            iconposition="end"
            openTo="day"
            views={["year", "month"]}
            value={filters.dataHoraLancamentoInicio}
            classes={{
              input: classes.inputData,
            }}
            onChange={(e) => handleChangeFilter(e, "dataHoraLancamentoInicio")}
          />
        </ItemGrid>
        <ItemGrid label="Data agendamento" size={2}>
          <InputDateForm
            iconposition="end"
            openTo="day"
            views={["year", "month"]}
            value={filters.dataAgendamento}
            classes={{
              input: classes.inputData,
            }}
            onChange={(e) => handleChangeFilter(e, "dataAgendamento")}
          />
        </ItemGrid>
        <ItemGrid label="Profissional" size={2}>
          <TextFieldSearch
            placeholder="Selecione"
            classInput={classes.reactSelect}
            classNotched={classes.notchedOutline}
            loadOptions={loadProfissionaisSaude}
            getOptionLabel={(option) => option?.nome}
            getOptionValue={(option) => option?.id}
            value={filters?.profissionalSaude}
            onChange={(e) => handleChangeFilter(e, "profissionalSaude")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </ItemGrid>
        <ItemGrid label="Sujeito de Atenção" size={2}>
          <TextFieldSearch
            placeholder="Selecione"
            classInput={classes.reactSelect}
            classNotched={classes.notchedOutline}
            loadOptions={loadSujeitosAtencao}
            getOptionLabel={(option) => option?.nome}
            getOptionValue={(option) => option?.id}
            value={filters?.sujeitoAtencao}
            onChange={(e) => handleChangeFilter(e, "sujeitoAtencao")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </ItemGrid>
        <Button
          bgColor="#707C97"
          onClick={handleClickFilter}
          disabled={loading}
          style={{ height: 40 }}
        >
          Filtrar
        </Button>
      </div>
    </div>
  );
};

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 20px",
    maxHeight: "97px",
    height: "100%",
    background: "#fff",
    boxShadow:
      "10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)",
  },
  contentTitleHeader: {
    display: "flex",
    justifyContent: "space-between",
    height: "40px",
  },
  titleHeader: {
    fontWeight: 700,
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    color: "#505050",
  },
  contentFilters: {
    display: "flex",
    alignItems: "center",
  },
  buttonFiltrar: {
    marginTop: "15px",
  },
  search: {
    height: 28,
    width: "100%",
    "&> div": {
      border: "1px solid rgba(220, 220, 220, 0.2)",
    },
  },
  notchedOutline: {
    border: "0",
  },
  reactSelect: {
    color: "#505050",
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    fontSize: "12px !important",
  },
};

export default withStyles(styles)(HeaderFilters);
