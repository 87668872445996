import React, { useEffect, useState } from "react";
import Dialog from "../../Dialog/Dialog";
import Table from "../../Table/Table";
import { columns } from "./constants";
import { orcamentosByAgendamento } from "../../../services/OrcamentoService";
import Scroll from "../../InfiniteScroll/Scroll";
import { CircularProgress, withStyles } from "@material-ui/core";
import MensagemListaVazia from "../../Mensagem/MensagemListaVazia";
import CloseIcon from "../../Icon/CloseIcon";
import { Button } from "../../ui/Buttons";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";

const ModalOrcamentosListByAgendamento = observer(
  ({
    classes,
    open,
    onClose,
    agendamentoId,
    history,
    atendimentoStore,
    sujeitoAtencaoStore,
  }) => {
    const [orcamentos, setOrcamentos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [last, setLast] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
      loadOrcamentos({ isClear: true });
    }, []);

    const loadOrcamentos = async (options) => {
      try {
        if (loading) return;
        setLoading(true);
        const pageableDTO = {
          pageNumber: options?.isClear ? 0 : pageNumber,
          pageSize: 10,
          sortDir: "DESC",
          sortField: "dataHoraLancamento",
        };

        const response = await orcamentosByAgendamento({
          agendamentoId,
          pageableDTO,
        });

        const { last, content } = response || {};

        setOrcamentos(options?.isClear ? content : [...orcamentos, ...content]);
        setLast(last);
        setPageNumber(options?.isClear ? 0 : pageNumber + 1);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    const handleSelectOrcamento = (orcamentoId) => {
      const sujeitoAtencaoId = atendimentoStore.objView.sujeitoAtencao?.id;
      const { objView } = atendimentoStore;

      try {
        if (sujeitoAtencaoId) {
          sujeitoAtencaoStore.reset();
          sujeitoAtencaoStore.changeId(sujeitoAtencaoId);
          return history.push({
            pathname: `/sujeito-atencao/edit/${sujeitoAtencaoId}/orcamentos`,
            state: {
              agendamentoId: objView.id,
              orcamentoId,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <div className={classes.header}>
          Selecione o orçamento:
          <Button
            shape="circle"
            bgColor={"#FB7676"}
            onClick={onClose}
            padding={0}
            style={{ height: 24, width: 24 }}
          >
            <CloseIcon />
          </Button>
        </div>
        <Scroll
          pageStart={0}
          loadMore={loadOrcamentos}
          hasMore={!last}
          initialLoad={false}
          className={classes.scrollContainer}
        >
          <Table
            dados={orcamentos}
            columns={columns}
            clickable={true}
            handleClick={handleSelectOrcamento}
          />
          {!loading && orcamentos.length === 0 && <MensagemListaVazia />}
          {loading && (
            <div className={classes.contentLoading}>
              <CircularProgress />
            </div>
          )}
        </Scroll>
      </Dialog>
    );
  }
);

const styles = {
  dialogPaper: {
    height: "300px",
    width: "530px",
  },
  header: {
    margin: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  scrollContainer: {
    height: "100%",
  },
  contentLoading: {
    display: "flex",
    justifyContent: "center",
  },
};

const ModalOrcamentosListByAgendamentoWithStyles = withStyles(styles)(
  ModalOrcamentosListByAgendamento
);
export default inject(
  "atendimentoStore",
  "sujeitoAtencaoStore"
)(ModalOrcamentosListByAgendamentoWithStyles);
