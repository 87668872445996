import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  filtersDefault,
  notificationDefault,
  pageableDTODefault,
} from "./constants";
import Notification from "../../../../components/Notification";
import { findAllProcedimentoOdontologicoSujeitoAtencao } from "../../../../services/ProcedimentoOdontologicoSujeitoAtencaoService";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import Filters from "./components/Filters";
import ProcedimentoOdontologicoSujeitoAtencaoTable from "./components/ProcedimentoOdontologicoSujeitoAtencaoTable";
import Modal from "./components/Modal/Modal";

const ProcedimentosOdontologicos = observer(
  ({ classes, sujeitoAtencaoStore }) => {
    const [procedimentosOdontologico, setProcedimentosOdontologico] = useState(
      []
    );
    const [filters, setFilters] = useState(filtersDefault);
    const [loading, setLoading] = useState(false);
    const [pageable, setPageable] = useState(pageableDTODefault);
    const [last, setLast] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [
      procedimentoOdontologicoSelected,
      setProcedimentoOdontologicoSelected,
    ] = useState(null);
    const [orcamentoSelected, setOrcamentoSelected] = useState(null);
    const [notification, setNotification] = useState(notificationDefault);

    const { idOpened } = sujeitoAtencaoStore;

    const { procedimentoRealizado, status } = filters;

    useEffect(() => {
      loadProcedimentosOdontologico();
    }, []);

    useEffect(() => {
      setTimeout(() => {
        closeAlertMessage();
      }, 3000);
    }, [notification]);

    const closeAlertMessage = () => {
      setNotification((prevState) => ({ ...prevState, open: false }));
    };

    const openNotification = (message, type = null) => {
      setNotification({
        open: true,
        message,
        type,
      });
      const timeoutId = setTimeout(() => {
        closeAlertMessage();
        clearTimeout(timeoutId);
      }, 3000);
    };

    const loadProcedimentosOdontologico = async (options) => {
      try {
        setLoading(true);

        const response = await findAllProcedimentoOdontologicoSujeitoAtencao({
          orcado: true,
          sujeitoAtencaoId: idOpened,
          ...(procedimentoRealizado !== null && {
            procedimentoRealizado: procedimentoRealizado.value,
          }),
          ...(status?.value && { status: [status.value] }),
          pageableDTO: {
            ...pageable,
            pageNumber: options?.isClearable ? 0 : pageable.pageNumber,
          },
        });

        const procedimentos = options?.isClearable
          ? response
          : [...procedimentosOdontologico, ...response];

        setProcedimentosOdontologico(procedimentos);
        setPageable({
          ...pageable,
          pageNumber: options?.isClearable ? 1 : pageable.pageNumber + 1,
        });
        setLast(last);
      } catch (error) {
        openNotification(
          "Erro ao carregar os procedimentos odontológicos",
          "error"
        );
      } finally {
        setLoading(false);
      }
    };

    const handleFilterChange = (value, field) => {
      setFilters({
        ...filters,
        [field]: value,
      });
    };

    const onCloseModal = () => {
      setOpenModal(false);
    };

    const OpenNotification = (e) => {
      setNotification({ ...e });
    };

    return (
      <div className={classes.container}>
        <Filters
          filters={filters}
          handleFilterChange={handleFilterChange}
          loadProcedimentosOdontologico={loadProcedimentosOdontologico}
        />
        <ProcedimentoOdontologicoSujeitoAtencaoTable
          loadProcedimentosOdontologico={loadProcedimentosOdontologico}
          procedimentosOdontologico={procedimentosOdontologico}
          loading={loading}
          setProcedimentoOdontologicoSelected={
            setProcedimentoOdontologicoSelected
          }
          setOpenModal={setOpenModal}
          setNotification={(e) => OpenNotification(e)}
          setOrcamentoSelected={setOrcamentoSelected}
        />
        {openModal && (
          <Modal
            open={openModal}
            onClose={onCloseModal}
            procedimentoOdontologicoSelected={procedimentoOdontologicoSelected}
            orcamentoSelected={orcamentoSelected}
          />
        )}
        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.open}
          variant={notification.type}
          message={notification.message}
        />
      </div>
    );
  }
);

const style = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px 16px 0",
  },
};

const ProcedimentosOdontologicosWithStyles = withStyles(style)(
  ProcedimentosOdontologicos
);
export default inject("sujeitoAtencaoStore")(
  ProcedimentosOdontologicosWithStyles
);
