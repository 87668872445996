import moment from "moment";

const primeiroDiaMesAtual = moment().startOf("month").format("YYYY-MM-DD");
const ultimoDiaMesAtual = moment().endOf("month").format("YYYY-MM-DD");

export const columns = [
    {
        Header: "Procedimento",
        align: "left",
        getValue: (data) => {
            return data.procedimento;
        },
        field: "procedimento"
    },
    {
        Header: "Situação do agendamento",
        align: "left",
        getValue: (data) => {
            return data.situacao;
        },
    },
    {
        Header: "Quantidade",
        align: "left",
        getValue: (data) => {
            return data.quantidade;
        },
    },
];
export const situacoesOptionsDefault = [
    {
        nome: "Todas as situações",
        value: "TODAS_SITUACOES",
        checked: false
    },
    {
        nome: "Agendado",
        value: "AGENDADO",
        checked: true
    },
    {
        nome: "Aguardando",
        value: "AGUARDANDO",
        checked: false
    },
    {
        nome: "Cancelado",
        value: "CANCELADO",
        checked: false
    },
    {
        nome: "Excluído",
        value: "EXCLUIDO",
        checked: false
    },
    {
        nome: "Confirmado",
        value: "CONFIRMADO",
        checked: false
    },

]

export const filtersDefault = {
    dataInicio: primeiroDiaMesAtual,
    dataFim: ultimoDiaMesAtual,
    profissionalSaude: null,
    situacoes: ["AGENDADO"],
    faturado: false,
    convenioId: null,
    procedimentoId: null,
};

export const pageableDTODefault = {
    pageNumber: 0,
    pageSize: 30,
    sortField: "procedimento",
    sortDir: "ASC",
}

export const filtersRelatorioPeriodoDefault = {
    profissionalSaude: null,
    procedimento: null,
    convenios: null,
    sujeitoAtencao: null,
    dataFim: ultimoDiaMesAtual,
    dataInicio: primeiroDiaMesAtual,
};
