import moment from "moment";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import string from "../../../../../../../utils/string";
import {
  defaultErrors,
  orcamentoDefault,
} from "../../../../../../../stores/Orcamento.store";
import {
  findConfiguracaoUnidade,
  saveOrcamento,
} from "../../../../../../../services/OdontogramaService";
import Notification from "../../../../../../../components/Notification";
import OrcamentoPdf from "../../../../../../../template/pdf/orcamentos/OrcamentoPdf";
import { verificaIsDifferent } from "../../../../../../../utils/verificaOrcamentosDiferentes";
import ModalOrcamento from "./Components/ModalOrcamento";
import ImpressaoHtml from "../../../../../../../components/Impressao/ImpressaoHtml";
import ProcedimentosOdontograma from "../ProcedimentosOdontograma";
import { tipoProfissionalSaude } from "../../../../../../../utils/constants/tipoProfissionalSaude";

const ModalOrcamentoOdontologico = observer(
  ({
    orcamentoStore,
    odontogramaStore,
    setOpenModalOrcamento,
    openModalOrcamento,
    sujeitoAtencaoSelected,
    loadList,
    orcamentoComparar,
    configuracaoImpressaoStore,
    agendamentoSelected,
    statusBack,
    prontuarioStore,
  }) => {
    const { orcamento, handleFieldModal, notification, closeAlertMessage } = orcamentoStore;

    const [configUnidade, setConfigUnidade] = useState(null);
    const [isLoadingProcedimentos, setIsLoadingProcedimentos] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [openImpressaoOrcamento,setoOpenImpressaoOrcamento] =useState(false)
    const [orcamentoPrint,setOrcamentoPrint]=useState(null)

    const {
      nome,
      convenio,
      dataVencimento,
      desconto,
      id,
      observacao,
      orcamentoProcedimentos,
      profissionalSaude,
      statusOrcamentoOdontologico,
      tipoPagamento,
      totalParcelas,
      valor,
      valorEntrada,
    } = orcamento || {};

    const profissionalSaudeLogado = JSON.parse(localStorage[`_immortal|profissional_saude_logado`] || null);
    const isAdministrador = tipoProfissionalSaude[profissionalSaudeLogado?.tipo] === "Administrador";

    const isDisabled = id && (!isAdministrador && statusBack?.tipo === "REPROVADO");

    useEffect(() => {
      !id && getDadosOrcamento();
      getConfigUnidade();
      orcamentoStore.errors = defaultErrors;
    }, []);

    const getDadosOrcamento = async () => {
      await getProcedimentosOdontograma();
    };

    const getConfigUnidade = async () => {
      const configUnidade = await findConfiguracaoUnidade();

      setConfigUnidade(configUnidade);
    };

    const contemErros = () => {
      const procedimentoChecked = orcamentoProcedimentos.some(
        (item) => item.checked
      );
      const errorDesconto = orcamentoStore.errorLimiteDesconto(
        configUnidade.limiteDescontoOrcamento
      );
      const errors = {
        procedimentos: !procedimentoChecked,
        tipoPagamento: !tipoPagamento,
        valor: !valor,
        desconto: errorDesconto,
      };

      orcamentoStore.errors = errors;
      const possuiErros = Object.values(errors).some((item) => item);

      return possuiErros;
    };

    const handleSave = async (action = "") => {
      try {
        if (contemErros()) {
          const message = orcamentoStore.errors.desconto
            ? "Limite de desconto ultrapassado."
            : "Favor verificar os campos obrigatórios";

              orcamentoStore.openNotification(message, "error");
              throw new Error(orcamentoStore.errors[0])
        }

        setIsLoadingSave(true);
        const procedimentosChecked = orcamentoProcedimentos
          .filter((item) => item.checked)
          .map((item) => {
            const { dente, id, mucosaOral, valorUnitario, status } = item;
            return {
              procedimentoOdontologico: {
                id,
                status: status.value,
                ...(mucosaOral && { mucosaOral }),
                ...(dente && { dente }),
              },
              valorUnitario:
                typeof valorUnitario === "string"
                  ? string.currencyMaskToFloat(valorUnitario)
                  : valorUnitario,
              quantidade: 1,
            };
          });

        let descontoNumber = 0;

        if (desconto?.valorDesconto) {
          descontoNumber =
            desconto?.tipoDesconto?.value === "VALOR"
              ? string.currencyMaskToFloat(desconto.valorDesconto)
              : string.removeMask(String(desconto.valorDesconto), "%");
        }

        const agendamentoId = agendamentoSelected?.id || orcamento?.agendamento?.id;

        const variables = {
          ...(id && { id }),
          nome,
          observacao,
          statusOrcamentoOdontologico,
          orcamentoProcedimentos: procedimentosChecked,
          valor:
            typeof valor === "string"
              ? string.currencyMaskToFloat(valor)
              : valor,
          tipo: "ODONTOLOGICO",
          profissionalSaude: {
            id: profissionalSaude?.id,
          },
          ...(totalParcelas && { totalParcelas: totalParcelas?.value }),
          ...(valorEntrada && {
            valorEntrada:
              typeof valorEntrada === "string"
                ? string.currencyMaskToFloat(valorEntrada)
                : valorEntrada,
          }),
          ...(convenio?.value && {
            convenio: { id: convenio?.value },
          }),
          ...(dataVencimento && {
            dataVencimento: moment(dataVencimento).format("YYYY-MM-DD"),
          }),
          ...(descontoNumber && {
            desconto: {
              valorDesconto: descontoNumber,
              tipoDesconto: desconto?.tipoDesconto?.value,
            },
          }),
          ...(tipoPagamento?.value && {
            tipoPagamento: tipoPagamento?.value,
          }),
          sujeitoAtencao: {
            id: sujeitoAtencaoSelected?.id,
          },
          ...(agendamentoId && {
            agendamento: {
              id: agendamentoId
            }
          })
        };

        const response = await saveOrcamento(variables);
         if(response.id){
          prontuarioStore.openNotification(
            "Orçamento salvo com sucesso!",
            "success"
          );
         }

        if (response?.statusOrcamentoOdontologico?.tipo === "PENDENTE_APROVACAO") {
          orcamentoStore.orcamento = orcamentoDefault;
        } else if (response?.statusOrcamentoOdontologico?.tipo === "APROVADO") {
          orcamentoStore.orcamento = {
            ...orcamentoStore.orcamento,
            id: response?.id,
          };
          odontogramaStore.dadosModalConfirmacao.open = true
        }
        await loadList();

        action !== "PRINT" && setOpenModalOrcamento(false);
    
        return response?.id

      } catch (e) {
        orcamentoStore.openNotification("Erro ao salvar o orçamento", "error");
      } finally {
        setIsLoadingSave(false);
      }
    };

    const getProcedimentosOdontograma = async () => {
      try {
        setIsLoadingProcedimentos(true);
        await odontogramaStore.findAllProcedimentoOdontologicoSemOrcamento({
          sujeitoAtencaoId: sujeitoAtencaoSelected?.id,
        });
      } catch(e) {
        orcamentoStore.openNotification(
          "Erro ao carregar os procedimentos odontológicos",
          "error"
        );
      } finally {
        setIsLoadingProcedimentos(false);
      }
    };


    const getOrcamentoPrint = async () => {

      if (!id) {
        const idNovoOrcamento = await handleSave("PRINT");
        return await orcamentoStore.getDadosOrcamentoOdontologico(idNovoOrcamento, sujeitoAtencaoSelected.id);
      } else {
        const orcamentoSelecionado = { ...orcamento };
        const orcamentoComparacao = orcamentoComparar;

        if (verificaIsDifferent(orcamentoSelecionado, orcamentoComparacao, true)) {
          const idOrcamentoUpdate = await handleSave();
          return await orcamentoStore.getDadosOrcamentoOdontologico(idOrcamentoUpdate);
        } else {
          return orcamento;
        }
      }
    }

    const handlePrint = async () => {
      try {
        await configuracaoImpressaoStore.getConfig("ORCAMENTO");
        let orcamentoPrint = await getOrcamentoPrint()
        let orcamentoProcedimentos = orcamentoPrint.orcamentoProcedimentos.filter(item => item.checked)
        const orcamentoImprimir = { ...orcamentoPrint, orcamentoProcedimentos }
        setOrcamentoPrint(orcamentoImprimir)
        setoOpenImpressaoOrcamento(true);
      } catch (e) {
        console.error(e);
      }
    };

    return (
      <>
        <ModalOrcamento
          open={openModalOrcamento}
          onClose={() => setOpenModalOrcamento(false)}
          title={id ? "Editar orçamento" : "Emitir orçamento"}
          handleSave={handleSave}
          orcamento={orcamento}
          handleFieldModal={handleFieldModal}
          isLoadingSave={isLoadingSave}
          setIsLoadingSave={setIsLoadingSave}
          handlePrint={handlePrint}
          sujeitoAtencaoSelected={sujeitoAtencaoSelected}
          statusBack={statusBack}
        >
          <ProcedimentosOdontograma
            isLoadingProcedimentos={isLoadingProcedimentos}
            isDisabled={isDisabled}
            orcamento={orcamento}
            handleFieldModal={handleFieldModal}
          />
        </ModalOrcamento>
        {openImpressaoOrcamento &&
          <ImpressaoHtml
            handlePrintMustache={() => setoOpenImpressaoOrcamento(false)}
            habilitaBotaoImpressao={()=> setOpenModalOrcamento(false)}
            isPrintMustache={openImpressaoOrcamento}
            htmlStringComponent={
              <OrcamentoPdf
                orcamento={orcamentoPrint}
              />}
          />}
        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
      </>
    );
  }
);

export default inject(
  "odontogramaStore",
  "orcamentoStore",
  "configuracaoImpressaoStore",
  "prontuarioStore",
)(ModalOrcamentoOdontologico);
