import Api from "../config/api";

export const findAllOrcamentoOdontologico = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($profissionalSaudeId: Long, $dataAgendamento: LocalDate, $tipo: OrcamentoTipo, $search: String, $sujeitoAtencaoId: UUID, $dataHoraLancamentoInicio: LocalDateTime, $dataHoraLancamentoFim: LocalDateTime, $pageableDTO: PageableDTOInput, $statusOrcamentoOdontologicoId: UUID, $numero: Long) {
      findAllOrcamento(profissionalSaudeId: $profissionalSaudeId, dataAgendamento: $dataAgendamento, tipo: $tipo, search: $search, sujeitoAtencaoId: $sujeitoAtencaoId, dataHoraLancamentoInicio: $dataHoraLancamentoInicio, dataHoraLancamentoFim: $dataHoraLancamentoFim, pageableDTO: $pageableDTO, statusOrcamentoOdontologicoId: $statusOrcamentoOdontologicoId, numero: $numero) {
        last
        content {
          id
          numero
          agendamento {
            data
          }
          dataHoraLancamento
          statusOrcamentoOdontologico {
            nome
            tipo
          }
          historico {
            informacao
            dataHoraLancamento
          }
          sujeitoAtencao {
            nome
          }
          profissionalSaude {
            nome
          }
        }
      }
    }`,
    variables: {
      ...variables,
      tipo: "ODONTOLOGICO",
    },
  });
  if (!response.data.errors) {
    return response.data.data.findAllOrcamento;
  } else {
    throw response.data.errors[0];
  }
};

export const findAllSujeitoAtencao = async (id) => {
  const response = await Api.post("", {
    query: `
    query ($searchDTO: SearchSujeitoAtencaoDTOInput){
      findAllSujeitoAtencao(searchDTO: $searchDTO) {
        last
        content {
          id
          nome
        }
      }
    }`,
    variables: variables,
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.findAllSujeitoAtencao;
};

export const findAllHistoricoOrcamentoOdontologico = async (orcamentoId) => {
  const response = await Api.post("", {
    query: `
    query ($orcamentoId: UUID){
      findAllHistoricoOrcamentoOdontologico(orcamentoId: $orcamentoId) {
        id
        informacao
        dataHoraLancamento
        profissionalSaude {
          nome
        }
      }
    }`,
    variables: { orcamentoId },
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.findAllHistoricoOrcamentoOdontologico;
};
