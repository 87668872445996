import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../../../../../components/Icon/Close";
import { Button } from "../../../../../../../components/ui/Buttons";
import { InputForm } from "../../../../../../../components/Modal/Input";
import MultiToggleButtons from "../../../../../../../components/Button/MultiToggleButtons";
import Dialog from "../../../../../../../components/Dialog/Dialog";
import TabDetalhes from "./tabs/TabDetalhes";
import { defaultErrors } from "../../../../../../../stores/Orcamento.store";
import { findConfiguracaoUnidade } from "../../../../../../../services/OdontogramaService";
import TabObservacoes from "../../../../../../Atendimento/Tabs/Odontograma/Modal/Orcamento/TabObservacoes";
import { findAllLocalPalmilha } from "../../../../../../../services/OrcamentoService";
import ProcedimentosOrtopedicos from "./ProcedimentosOrtopedicos";

const tabs = {
  MODELO: 0,
  DETALHES: 1,
  OBSERVACOES: 2,
};

const ModalOrcamento = observer(
  ({
    classes,
    open,
    title,
    orcamentoStore,
    handleSave,
    onClose,
    isLoadingSave,
    setIsLoadingSave,
  }) => {
    const { orcamento, handleFieldModal } = orcamentoStore;
    const { nome, id, status, orcamentoProcedimentos } = orcamento || [];

    const [tabSelected, setTabSelected] = useState(tabs.MODELO);
    const [configUnidade, setConfigUnidade] = useState(null);

    const isDisabled = id && status !== "PENDENTE";

    useEffect(() => {
      getConfigUnidade();
      !orcamento?.id && getLocalPalmilha();
      orcamentoStore.errors = defaultErrors;
    }, []);

    const getLocalPalmilha = async () => {
      try {
        const response = await findAllLocalPalmilha();
        const locais = response.map((item) => {
          return {
            ...item,
            espessura: "",
            checked: false,
          };
        });

        handleFieldModal("locaisPalmilhaPeDireito", locais);
        handleFieldModal("locaisPalmilhaPeEsquerdo", locais);
      } catch (error) {
        console.error(error);
      }
    };

    const changeTabSelected = (tabSelected) => {
      setTabSelected(tabSelected);
    };

    const getConfigUnidade = async () => {
      const configUnidade = await findConfiguracaoUnidade();

      setConfigUnidade(configUnidade);
    };

    const handleAction = async (action) => {
      try {
        setIsLoadingSave(true);
        await handleSave(action);
        
      } catch (e) {
        throw e;
      } finally {
        setIsLoadingSave(false);
      }
    };

    const renderTab = () => {
      const components = [
        <ProcedimentosOrtopedicos
          handleFieldModal={handleFieldModal}
          orcamentoProcedimentos={orcamentoProcedimentos}
          orcamento={orcamento}
        />
        ,
        <TabDetalhes
          orcamento={orcamento}
          handleFieldModal={handleFieldModal}
        />,
        <TabObservacoes
          orcamento={orcamento}
          handleFieldModal={handleFieldModal}
        />,
      ];

      return components[tabSelected];
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={'md'}
        classes={{
          paper: classes.dialog,
        }}
      >
        <div className={classes.header}>
          {title}
          <Button bgColor="#FB7676" shape="circle" onClick={onClose}>
            <CloseIcon />
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.contentField}>
            <span className={classes.titleField}>Nome*</span>
            <InputForm
              value={nome}
              disabled={isDisabled}
              onChange={(e) => handleFieldModal("nome", e.target.value)}
              error={orcamentoStore.errors.nome}
            />
          </div>
          <MultiToggleButtons
            options={["Modelo", "Detalhes*", "Observação"]}
            tabSelected={tabSelected}
            changeTabSelected={changeTabSelected}
          />
          {renderTab()}
        </div>
        <div className={classes.buttons}>
          <Button
            bgColor="#26ACAA"
            onClick={() => handleAction("APROVADO")}
            width="205px"
            disabled={isLoadingSave || status === "APROVADO"}
            isLoading={isLoadingSave}
          >
            Salvar pedido
          </Button>
        </div>
      </Dialog>
    );
  }
);

const styles = {
  dialog: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px 32px 32px 32px",
    width: "100%",
    height: "100%",
    maxHeight: "640px",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "700",
    color: "#333333",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    height: 'calc(100% - 113px)'
  },
  buttons: {
    display: "flex",
    justifyContent: "end",
  },
  contentField: {
    display: "flex",
    flexDirection: "column",
  },
  titleField: {
    color: "#868686",
  },
};

const ModalOrcamentoWithStyles = withStyles(styles)(ModalOrcamento);
export default inject(
  "odontogramaStore",
  "prontuarioStore",
  "orcamentoStore"
)(ModalOrcamentoWithStyles);
