import React from 'react';
import styled from "styled-components";

const ContentStatus  = styled.div`
    background: ${props => props.statusOrcamento.backgroundColor};
    border: 0.5px solid ${props => props.statusOrcamento.color};
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    border-radius: 8px;
    color: #505050;
    font-weight: 600;
    gap: 4px;
    max-width: 194px;
    text-wrap: nowrap;
`;

const SituacaoOrcamento = ({ statusOrcamento }) => {
    const { label } = statusOrcamento || {};
   
    return(<ContentStatus statusOrcamento={statusOrcamento}>
        { label }
    </ContentStatus>)
};

export default SituacaoOrcamento;
