import { CircularProgress, withStyles } from "@material-ui/core";
import React from "react";
import Table from "../../../../../components/Table/Table";
import MensagemListaVazia from "../../../../../components/Mensagem/MensagemListaVazia";
import { columns } from "../constants";
import {
  findOrcamentoById,
  updateStatusProcedimento,
} from "../../../../../services/ProcedimentoOdontologicoSujeitoAtencaoService";

const ProcedimentoOdontologicoSujeitoAtencaoTable = ({
  classes,
  procedimentosOdontologico,
  loading,
  setProcedimentoOdontologicoSelected,
  setOrcamentoSelected,
  setOpenModal,
  loadProcedimentosOdontologico,
  setNotification,
}) => {
  const getDadosRow = async (value) => {
    const { orcamentoAtual } = value || {};

    const response = await findOrcamentoById(orcamentoAtual?.id);
    setOrcamentoSelected(response);
    setProcedimentoOdontologicoSelected(value);
    setOpenModal(true);
  };

  const handleUpdateOrcamento = async (e, status) => {
    try {
      const variables = {
        procedimentosOdontologicoId: [e.id],
        status: status,
      };
      await updateStatusProcedimento(variables);

      loadProcedimentosOdontologico({ isClearable: true });

      setNotification({
        open: true,
        message: "Salvo com sucesso!",
        type: "success",
      });
    } catch (e) {
      setNotification({
        open: true,
        message: "Ocorreu um erro!",
        type: "error",
      });
      console.error("Error", e);
    }
  };

  return (
    <div className={classes.container}>
      <Table
        dados={procedimentosOdontologico}
        columns={columns({
          functions: {
            handleFinalizar: (e) => handleUpdateOrcamento(e, "FINALIZADO"),
            handleCancelar: (e) => handleUpdateOrcamento(e, "CANCELAR_EXECUCAO"),
            handleEmAndamento: (e) => handleUpdateOrcamento(e, "EM_ANDAMENTO"),
          },
        })}
        clickable
        getDadosRow={getDadosRow}
      />
      {loading && (
        <div className={classes.wrapperLoading}>
          <CircularProgress />
        </div>
      )}
      {!loading && !procedimentosOdontologico.length && <MensagemListaVazia />}
    </div>
  );
};

const style = {
  container: {
    display: "flex",
    height: "calc(100vh - 250px)",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "16px",
    background: "#fff",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  wrapperLoading: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
  },
};

export default withStyles(style)(ProcedimentoOdontologicoSujeitoAtencaoTable);
