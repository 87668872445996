import moment from "moment";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Filter from "./components/Filter";
import string from "../../../../utils/string";
import { getColumns } from "./utils/getColumns";
import FilterModal from "./components/FilterModal";
import Table from "../../../../components/Table/Table";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import {
  findAllOrcamento,
  updateStatusOrcamento,
} from "../../../../services/OrcamentoService";
import ModalOrcamentoOdontologico from "../../../Atendimento/Tabs/Odontograma/Modal/Orcamento/ModalOrcamentoOdontologico";
import SpeedDials from "../../../../components/SpeedDials";
import ModalOrcamentoProcedimento from "./components/Modal/index";
import ModalOrcamentoOrtopedico from "./components/Modal/Ortopedico/index";
import CheckMarkIcon from "../../../../components/Icon/CheckMarkIcon";
import ModalConfirmacao from "../../../../components/Modal/ModalConfirmacao/ModalConfirmacao";
import { orcamentoDefault } from "../../../../stores/Orcamento.store";
import Notification from "../../../../components/Notification";
import { getProfissionalSaudeLogado } from "../../../../services/UsuarioService";
import { checkUserRole } from "../../../../utils/checkUserRole";

const primeiroDiaMesAtual = moment()
  .startOf("month")
  .format("YYYY-MM-DD");
const ultimoDiaMesAtual = moment()
  .endOf("month")
  .format("YYYY-MM-DD");

const filtersDefault = {
  profissionalSaude: null,
  procedimento: null,
  dataInicio: primeiroDiaMesAtual,
  dataFim: ultimoDiaMesAtual,
  pageableDTO: {
    pageNumber: 0,
    pageSize: 30,
  },
  status: null,
  tipo: null,
};

const pageableDTODefault = {
  sortDir: "DESC",
  sortField: "dataHoraLancamento",
};

const Orcamentos = observer(
  ({
    classes,
    odontogramaStore,
    extratoStore,
    sujeitoAtencaoStore,
    history,
    orcamentoStore,
    unidadeStore,
  }) => {
    const { dadosModalConfirmacao } = odontogramaStore;
    const { notification, closeAlertMessage } = orcamentoStore;

    const [filters, setFilters] = useState({ ...filtersDefault, search: "" });
    const [openfilterModal, setOpenFilterModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [orcamentos, setOrcamentos] = useState([]);
    const [last, setLast] = useState(false);
    const [openModalOrcamento, setOpenModalOrcamento] = useState(false);
    const [ordenarTabela, setOrdenarTabela] = useState(pageableDTODefault);
    const [orcamentoSelectedId, setOrcamentoSelectedId] = useState(null);
    const [typeOfOrcamento, setTypeOfOrcamento] = useState("");
    const [orcamentoComparar,setOrcamentoComparar]=useState(null)
    const [isGerenciador,setIsGerenciador]=useState(false)

    const {
      profissionalSaude,
      search,
      procedimento,
      dataInicio,
      dataFim,
      pageableDTO,
      status,
      tipo,
    } = filters;

    const { objView } = sujeitoAtencaoStore;
    const whitelabel = JSON.parse(
      localStorage["_immortal|unidadeLogada"] || null
    )?.rede?.whitelabel;

    const actions = [
      ...(whitelabel?.nome === "_3DPE" ?
        [
          {
            name: "Palmilhas 3DPÉ",
            buttonClasses: classes.buttonAddOrtopedico,
            tipo: "ORTOPEDICO",
          },
        ]
        : [
          {
            name: "Procedimento",
            buttonClasses: classes.buttonAddConsulta,
            tipo: "PROCEDIMENTO",
          },
          {
            name: "Odontológico",
            buttonClasses: classes.buttonAddProcedimento,
            tipo: "ODONTOLOGICO",
          },
        ])

    ];

    const popperRef = useRef(null);

    useEffect(() => {
      loadOrcamentos(true);
    }, [ordenarTabela]);

    useEffect(() => {
      const dados = history.location.state
      
      if (dados?.agendamentoId && dados?.orcamentoId) {
        getOrcamentosByAgendamento(dados.orcamentoId)
      }
    }, []);

    useEffect(() => {
      checkRoles()
    }, [ordenarTabela]);

    const checkRoles = async() =>{
      const isGerenciador = await checkUserRole("ROLE_GERENCIADOR_ORCAMENTO_UPDATE")
      setIsGerenciador(isGerenciador)
    }


    const handleClickFilterButton = () => {
      setOpenFilterModal(true);
    };

    const handleFilterChange = (value, field) => {
      setFilters({
        ...filters,
        [field]: value,
      });
    };

    const getOrcamentosByAgendamento = async(orcamentoId) => {
      try {
        const orcamento = await orcamentoStore.getDadosOrcamentoOdontologico(orcamentoId, sujeitoAtencaoStore.idOpened);
        setOrcamentoComparar(orcamento)
        setOrcamentoSelectedId(orcamento.id);
        setTypeOfOrcamento(orcamento.tipo);
        setOpenModalOrcamento(true)
      } catch (error) {
        console.error(error);
      }
    };

    const loadOrcamentos = async (clear = false) => {
      try {
        if(isLoading) return;

        setIsLoading(true);

        let orcamentoList = orcamentos;
        let page = pageableDTO.pageNumber;

        if (clear) {
          orcamentoList = [];
          page = 0;
        }

        const response = await findAllOrcamento({
          profissionalSaudeId: profissionalSaude?.value,
          sujeitoAtencaoId: sujeitoAtencaoStore.idOpened,
          search,
          procedimentoId: procedimento?.id,
          pageableDTO: {
            ...pageableDTO,
            ...ordenarTabela,
            pageNumber: page,
          },
          status: status?.value,
          ...(tipo?.value && {
            tipo: tipo?.value
          })
        });

        setOrcamentos([...orcamentoList, ...response.content]);
        setFilters({
          ...filters,
          pageableDTO: {
            ...pageableDTO,
            pageNumber: page + 1,
          },
        });
        setLast(response.last);
      } catch {
        odontogramaStore.openNotification(
          "Erro ao carregar os orcamentos",
          "error"
        );
      } finally {
        setIsLoading(false);
      }
    };

    const handleClickVisualizar = async (closePopper, data) => {
      if(data.tipo === "ORTOPEDICO"){
        await orcamentoStore.getDadosOrcamentoOrtopedico(data.id)
      }else{
        const orcamento = await orcamentoStore.getDadosOrcamentoOdontologico(data.id);
        setOrcamentoComparar(orcamento)
      }
      setOrcamentoSelectedId(data.id);
      setTypeOfOrcamento(data.tipo);
      setOpenModalOrcamento(true);
      return closePopper;
    };

    const handleClickFaturar = async (closePopper) => {
      try {
        const is3dpe = whitelabel?.nome === "_3DPE";
        if(is3dpe) {
          // const link3dpe = await urlPainel3dpe();
          const link3dpe = "https://interno.3dpedoctors.com.br/compra-palmilha";

          window.open(link3dpe, "_blank");

          return closePopper;
        };

        await unidadeStore.inicializaConfiguracaoUnidade();
        const orcamentoId = orcamentoStore?.orcamento?.id

        const { configuracaoUnidade } = unidadeStore;
        const { azulControleAtivado, utilizaFinanceiroAppHealth } =
          configuracaoUnidade || {};

        const showFinanceiroAzulControle =
          azulControleAtivado && !utilizaFinanceiroAppHealth;

        showFinanceiroAzulControle
          ? await orcamentoStore.handleFaturarFinanceiroCompleto(orcamentoId)
          : await handleFaturarFinanceiroSimples(orcamentoId);

        dadosModalConfirmacao.open = false;

        return closePopper;
      } catch (e) {
        console.error(e);
      }
    };

    const handleFaturarFinanceiroSimples = async (orcamentoId) => {
      try {
        await orcamentoStore.getDadosOrcamentoOdontologico(
          orcamentoId
        );
        const {
          profissionalSaude,
          valor,
          desconto,
          tipoDesconto,
          orcamentoProcedimentos,
        } = orcamentoStore.orcamento || {};

        const orcamento = {
          ...orcamentoStore.orcamento,
          subtotal: string.calculaDesconto(valor, desconto, tipoDesconto),
        };

        const procedimentos = orcamentoProcedimentos.filter(item => item.checked).map(item => {
          const procedimento = item.procedimento?.id? item.procedimento : item.procedimentoOdontologico?.procedimento;

          return procedimento;
        })

        extratoStore.preencherDadosOrcamentoOdontologico({
          tipo: "RECEITA",
          sujeitoAtencao: objView,
          ...(profissionalSaude?.id && {
            profissionalSaude: {
              ...profissionalSaude,
              label: profissionalSaude.nome,
              value: profissionalSaude.id,
            },
          }),
          procedimentos,
          orcamento: orcamento,
        });
        history.push("/financeiro");
        dadosModalConfirmacao.open = false;
      } catch (error) {
        console.log(error);
      }
    };

    const handleExcluir = async (closePopper, data) => {
      await updateStatusOrcamento({
        orcamentoId: data.id,
        status: "CANCELADO",
      });

      loadOrcamentos(true);
      return closePopper;
    };

    const handleClickOrdenar = (value) => {
      const sortDir =
        ordenarTabela.sortField !== value
          ? "ASC"
          : ordenarTabela.sortDir === "ASC"
          ? "DESC"
          : "ASC";
      setOrdenarTabela({
        sortDir: sortDir,
        sortField: value,
      });
    };

    const handleClickAction = async(action) => {
      const { dadosConvenio } = objView || {};
      const { convenio } = dadosConvenio || {};

      const profissionalLogado = await getProfissionalSaudeLogado();

      orcamentoStore.orcamento = {
        ...orcamentoDefault,
        tipo: action.tipo,
        ...( convenio?.id && {
          convenio: {
            ...convenio,
            label: convenio?.descricao,
            value: convenio?.id,
          }
        }),
        profissionalSaude: profissionalLogado,
      };
      setTypeOfOrcamento(action.tipo);
      setOpenModalOrcamento(true);
    };

    const handleCloseModalConfirmacao = () => {
      setOpenModalOrcamento(false);
      orcamentoStore.orcamento = orcamentoDefault;
      dadosModalConfirmacao.open = false;
    };

    return (
      <div className={classes.content}>
        <Filter
          onClickFilterButton={handleClickFilterButton}
          onChange={handleFilterChange}
          filter={filters}
          handleApplyFilter={() => loadOrcamentos(true)}
          isLoading={isLoading}
        />
        <div className={classes.contentTable}>
          <Scroll
            loadMore={() => loadOrcamentos()}
            hasMore={!last}
            pageStart={0}
            initialLoad={false}
            className={classes.scrollContainer}
          >
            <Table
              dados={orcamentos}
              columns={getColumns({
                handleClickVisualizar,
                whitelabel,
                popperRef,
                isGerenciador,
                functionsPopper: {
                  handlePrint: () => {},
                  handleExcluir,
                  handleDownload: () => {},
                  handleFaturar: handleClickFaturar,
                },
              })}
              emptyMessage={
                <div className={classes.emptyMessage}>
                  <span>Nenhum registro encontrado</span>
                </div>
              }
              isLoading={isLoading}
              comOrdenacao
              ordenarTabela={ordenarTabela}
              handleClickOrdenar={handleClickOrdenar}
            />
          </Scroll>
        </div>

        {openfilterModal && (
          <FilterModal
            open={openfilterModal}
            handleClose={() => setOpenFilterModal(false)}
            isLoading={isLoading}
            handleApplyFilter={() => loadOrcamentos(true)}
            filters={filters}
            resetFilter={() => setFilters({ ...filtersDefault })}
            handleFilterChange={handleFilterChange}
          />
        )}
        {openModalOrcamento && typeOfOrcamento === "ODONTOLOGICO" && (
          <ModalOrcamentoOdontologico
            openModalOrcamento={openModalOrcamento}
            sujeitoAtencaoSelected={objView}
            setOpenModalOrcamento={setOpenModalOrcamento}
            loadList={() => loadOrcamentos(true)}
            orcamentoComparar={{...orcamentoComparar}}
          />
        )}
        {openModalOrcamento && typeOfOrcamento === "PROCEDIMENTO" && (
          <ModalOrcamentoProcedimento
            openModalOrcamento={openModalOrcamento}
            sujeitoAtencaoSelected={objView}
            setOpenModalOrcamento={setOpenModalOrcamento}
            loadList={() => loadOrcamentos(true)}
            orcamentoComparar={{...orcamentoComparar}}
          />
        )}
        {openModalOrcamento && typeOfOrcamento === "ORTOPEDICO" && (
          <ModalOrcamentoOrtopedico
            openModalOrcamento={openModalOrcamento}
            sujeitoAtencaoSelected={objView}
            setOpenModalOrcamento={setOpenModalOrcamento}
            loadList={() => loadOrcamentos(true)}
          />
        )}
        {dadosModalConfirmacao.open && (
          <ModalConfirmacao
            open={dadosModalConfirmacao.open}
            icon={<CheckMarkIcon />}
            onClose={() => (dadosModalConfirmacao.open = false)}
            color={"green"}
            title={"Orçamento aprovado! Deseja faturar?"}
            buttons={{
              labelPrimaryButton: "Fatura depois",
              labelSecondButton: "Faturar agora",
              colorCustomPrimaryButton: "transparency",
              colorCustomSecondButton: "green",
              handlePrimaryButton: () => handleCloseModalConfirmacao(),
              handleSecondButton: () => handleClickFaturar(null, orcamentoSelectedId),
            }}
          ></ModalConfirmacao>
        )}
        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
        <div className={classes.floatButtonsContainer}>
          <SpeedDials
            actions={actions}
            onClickAction={handleClickAction}
            withTooltip={false}
          />
        </div>
      </div>
    );
  }
);

const style = {
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "16px 16px 0 16px",
    gap: "16px",
  },
  contentTable: {
    display: "flex",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    backgroundColor: "#fff",
    height: "calc(100vh - 255px)",
  },
  emptyMessage: {
    display: "flex",
    justifyContent: "center",
    marginTop: "8px",
  },
  scrollContainer: {
    height: "100%",
  },
  floatButtonsContainer: {
    position: "absolute",
    bottom: 24,
    right: 37,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "end",
    columnGap: "8px",
    "& > div": {
      alignItems: "flex-end",
    },
  },
  buttonAddProcedimento: {
    backgroundColor: "#707C97 !important",
    width: "fit-content",
    borderRadius: "100px",
    border: "2px solid rgba(220, 220, 220, 0.2)",
    padding: "8px 16px",
    color: "#fff",
    whiteSpace: "nowrap",
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
  },
  buttonAddConsulta: {
    backgroundColor: "#26ACA9 !important",
    width: "fit-content",
    borderRadius: "100px",
    border: "2px solid rgba(220, 220, 220, 0.2)",
    padding: "8px 16px",
    color: "#fff",
    whiteSpace: "nowrap",
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "bold",
  },
  buttonAddOrtopedico: {
    backgroundColor: "#1C5F8C !important",
    width: "fit-content",
    borderRadius: "100px",
    border: "2px solid rgba(220, 220, 220, 0.2)",
    padding: "8px 16px",
    color: "#fff",
    whiteSpace: "nowrap",
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "bold",
  },
};

const OrcamentosWithStyles = withStyles(style)(Orcamentos);
export default inject(
  "odontogramaStore",
  "extratoStore",
  "sujeitoAtencaoStore",
  "orcamentoStore",
  "unidadeStore"
)(OrcamentosWithStyles);
