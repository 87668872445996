import React, { useState } from "react";
import { ReactSelect } from "../../../../../components/Select/SelectSearch";
import { Button } from "../../../../../components/ui/Buttons";
import { filterRealizado, filterSituacaoOrcamento } from "../Constants";
import { withStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import { findAllProcedimentoOdontologico } from "../../../../../services/OdontogramaService";

const Filters = observer(({ classes, odontogramaStore }) => {
  const [filters, setFilters] = useState({
    procedimentoRealizado: null,
    situacaoOrcamento: null,
  });

  const handleFilter = (e, field) => {
    setFilters({
      ...filters,
      [field]: e,
    });
  };

  const onClickFilter = async () => {
    try {
      odontogramaStore.listagemProcedimentos.loading = true;

      const variables = {
        odontogramaId: odontogramaStore.odontograma.id,
        pageableDTO: {
          sortDir: "DESC",
          sortField: "dataHoraLancamento",
        },
        orcado: true,
        procedimentoRealizado: filters.procedimentoRealizado?.value,
        orcamentoStatus: filters.situacaoOrcamento?.value,
      };

      const response = await findAllProcedimentoOdontologico(variables);
      odontogramaStore.listagemProcedimentos.procedimentosOdontologicos = response;
    } catch (error) {
      console.log(error);
    } finally {
      odontogramaStore.listagemProcedimentos.loading = false;
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.field}>
          <label>Status execução</label>
          <ReactSelect
            className={classes.reactSelect}
            value={filters.procedimentoRealizado}
            onChange={(e) => handleFilter(e, "procedimentoRealizado")}
            options={filterRealizado}
            isClearable
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </div>
        <div className={classes.field}>
          <label>Situação orçamento</label>
          <ReactSelect
            className={classes.reactSelect}
            value={filters.situacaoOrcamento}
            onChange={(e) => handleFilter(e, "situacaoOrcamento")}
            options={filterSituacaoOrcamento}
            isClearable
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </div>
      </div>
      <Button bgColor="#F9BE73" onClick={onClickFilter}>
        Filtrar
      </Button>
    </div>
  );
});

const style = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "8px",
  },
  content: {
    display: "flex",
    gap: "8px",
  },
  reactSelect: {
    width: "200px",
  },
  field: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
};

const FiltersWithStyles = withStyles(style)(Filters);
export default inject("odontogramaStore")(FiltersWithStyles);
