import React, { useEffect, useState } from "react";
import Table from "../../../../../../components/Table/Table";
import { columnsHistoricoAgendamentos } from "../../constants";
import { findAllAgendamentoByProcedimentoOdontologico } from "../../../../../../services/ProcedimentoOdontologicoSujeitoAtencaoService";
import { CircularProgress, withStyles } from "@material-ui/core";

const TabHistoricoAgendamentos = ({
  classes,
  procedimentoOdontologicoSelected,
  orcamentoSelected,
}) => {
  const [agendamentos, setAgendamentos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadAgendamentos();
  }, []);

  const loadAgendamentos = async () => {
    try {
      setIsLoading(true);
      const response = await findAllAgendamentoByProcedimentoOdontologico({
        orcamentoId: orcamentoSelected.id,
        procedimentoOdontologicoId: procedimentoOdontologicoSelected.id,
      });
      setAgendamentos(response);

    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <Table dados={agendamentos} columns={columnsHistoricoAgendamentos} />
      {isLoading && <CircularProgress /> }
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    overflow: 'auto',
    height: "100%",
  }
};

export default withStyles(styles)(TabHistoricoAgendamentos);
