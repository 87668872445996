import { mensagemPadrao } from "./constants/camposMensagemPadraoAuditoria";
import { entitiesAttributesName } from "./constants/entitiesAttributtesName";
import React from "react";

const getCamposModificados = (modificacao, type, withValue = false, auditChangesValues) => {
  const camposModificados = []
  
  const mensagens = {
    "paciente": {
      "alturaModified": 'A altura do paciente foi modificada',
      "ativoModified": 'O status do paciente foi modificado',
      "nomeResponsavelModified": 'O nome do responsável do paciente foi modificado',
      "documentoResponsavelModified": 'O documento do responsável do paciente foi modificado',
      "cnsModified": 'O CNS do paciente foi modificado',
      "comoConheceuModified": 'Como o paciente conheceu foi modificado',
      "documentoModified": 'O documento do paciente foi modificado.',
      "dataNascimentoModified": 'A data de nascimento do paciente foi modificada.',
      "fotoPerfilModified": 'A foto de perfil do paciente foi modificada.',
      "nomeConjugeModified": 'O nome do cônjuge do paciente foi modificado.',
      "nomeMaeModified": 'O nome da mãe do paciente foi modificado.',
      "nomeModified": 'O nome do paciente foi modificado.',
      "nomePaiModified": 'O nome do pai do paciente foi modificado.',
      "nomeSocialModified": 'O nome social do paciente foi modificado.',
      "observacaoModified": 'A observação do paciente foi modificada.',
      "pesoModified": 'O peso do paciente foi modificado.',
      "profissionalSaudeModified": 'O profissional de saúde foi modificado.',
      "quemIndicouModified": 'A indicação do paciente foi modificada.',
      "religiaoModified": 'A religião do paciente foi modificada.',
      "rgModified": 'O RG do paciente foi modificado.',
    },
    "lancamento": {
      "agendamentoModified": 'O agendamento foi modificado',
      "categoriaFinanceiraModified": 'A categoria financeira foi modificada',
      "centroCustoModified": 'O centro de custos foi modificado',
      "contaBancariaModified": 'A conta bancária foi modificada',
      "dataHoraLancamentoModified": 'A data e hora de lançamento foi modificado',
      "dataPagamentoModified": 'A data de pagamento foi modificado',
      "dataVencimentoModified": 'A data de vencimento foi modificada',
      "excluidoModified": 'Parcela excluído',
      "formaPagamentoModified": 'A forma de pagamento foi modificado',
      "nomeModified": 'O nome foi modificado',
      "numeroParcelaModified": 'O número de parcela foi modificado',
      "periodicidadeModified": 'A periodicidade foi modificada',
      "profissionalSaudeModified": 'O profissional foi modificado',
      "sujeitoAtencaoModified": 'O paciente foi modificado',
      "tipoModified": 'O tipo foi modificado',
      "tipoRepeticaoModified": 'O tipo de repetição foi modificado',
      "tokenPagamentoModified": 'O token do pagamento foi modificado',
      "totalParcelasModified": 'O total de parcelas foi modificado',
      "valorModified": 'O valor foi modificado',
    },
    "contaBancaria": {
      "nomeModified": 'O nome foi modificado',
      "bancoModified": 'O banco foi modificado',
      "saldoAtualModified": 'O saldo atual foi modificado',
      "saldoInicialModified": 'O saldo inicial foi modificado',
    },
    "categoriaFinanceira": {
      "nomeModified": 'O nome foi modificado',
      "tipoModified": 'O tipo foi modificado',
    },
    "centroCusto": {
      "nomeModified": 'O nome foi modificado',
      "ativoModified": 'O status foi modificado',
    },
    "produto": {
      "nomeModified": 'O nome foi modificado',
      "ativoModified": 'O status foi modificado',
    },
    "agendamentoTipo": {
      "descricaoModified": 'A descrição foi modificada',
      "codigoModified": 'O código foi modificado',
      "ativoModified": 'O status foi modificado',
    },
    "configuracaoHorarioAtendimento": {
      "dataFimVigenciaModified": 'A data fim da vigência foi modificada',
      "dataInicioVigenciaModified": 'A data início da vigência foi modificada',
      "duracaoModified": 'A duração foi modificada',
      "horaFimPeriodoAtendimentoModified": 'A hora fim do período foi modificada',
      "horaInicioPeriodoAtendimentoModified": 'A hora início do período foi modificada',
    },
    "medicamento": {
      "fabricanteModified": 'O fabricante foi modificado',
      "formaFarmaceuticaModified": 'A forma farmaceutica foi modificada',
      "principioAtivoModified": 'O princípio status foi modificado',
      "produtoModified": 'O produto foi modificado',
      "profissionalSaudeModified": 'O profissional saúde foi modificado',
      "receituarioModified": 'O receituário foi modificado',
      "statusModified": 'O status foi modificado',
    },
    "receitaModelo": {
      "ativoModified": 'O status foi modificado',
      "nomeModified": 'O nome foi modificado',
      "profissionalSaudeModified": 'O profissional saúde foi modificado',
      "quantidadeUsoModified": 'A quantidade de uso foi modificada',
      "tipoModified": 'O tipo foi modificado',
    },
    "anamneseModelo": {
      "nomeModified": 'O nome foi modificado',
      "ativoModified": 'O status foi modificado',
      "profissionalSaudeModified": 'O profissional saúde foi modificado',
    },
    "convenio": {
      "ativoModified": 'O status foi modificado',
      "descricaoModified": 'A descrição foi modificada',
      "codigoUnidadeModified": 'O código unidade foi modificado',
      "registroAnsModified": 'O registro ans foi modificado',
      "valorConsultaModified": 'O valor consulta foi modificado',
    },
    "guiaSevicoModelo": {
      "nomeModified": 'O nome foi modificado',
      "ativoModified": 'O status foi modificado',
      "indicacaoModified": 'A indicação foi modificada',
      "observacaoModified": 'A observação foi modificada',
      "profissionalSaudeModified": 'O profisional saúde foi modificado',
    },
    "procedimento": {
      "nomeModified": 'O nome foi modificado',
      "ativoModified": 'O status foi modificado',
    },
    "configuracaoUnidade": {
      "atendimentoExigeAssinaturaPacienteModified": 'Exige assinatura do paciente foi modificado',
      "azulControleAtivadoModified": 'O status do azul controle foi modificado',
      "enviaConfirmacaoAntecipaFinalSemanaModified": 'O status da confirmação da notificação de agendamento que antecipa no final de semana foi modificado',
      "enviaConfirmacaoDoisDiasAntesModified": 'O status da confirmação da notificação de agendamento de dois dias antes foi modificado',
      "enviaConfirmacaoDoisDiasAntesSalaModified": 'O status da confirmação da notificação de dois dias antes da sala foi modificado',
      "enviaConfirmacaoUmaHoraAntesModified": 'O status da confirmação da notificação de agendamento de uma hora antes foi modificado',
      "enviaConfirmacaoUmaHoraAntesSalaModified": 'O status da confirmação da notificação de uma hora antes da sala foi modificado',
      "marcarFaltososAutomaticamenteModified": 'O status de marcar faltosos automaticamente foi modificado',
      "notificaValorZeroAzulControleModified": 'O status de notificar se o valor do azul controle é zero foi modificado',
      "numeroHorasBloqueioAgendamentoModified": 'Horas que bloqueia a edição do agendamento foi modificado',
      "numeroHorasCancelamentoAutomaticoModified": 'Horas que cancela automaticamente foi modificado',
      "utilizaTelefoneInternacionalModified": 'O status da utilização de telefone internacional foi modificado',
    },
    "sala": {
      "nomeModified": 'O nome foi modificado',
      "ativoModified": 'O status foi modificado',
    },
    "guiaServicoModelo": {
      "nomeModified": 'O nome foi modificado',
      "ativoModified": 'O status foi modificado',
      "indicacaoModified": 'A indicação foi modificado',
      "observacaoModified": 'A observação foi modificado',
      "profissionalSaudeModified": 'O profissionalfoi modificado',
    },
    "documentoModelo": {
      "nomeModified": 'O nome foi modificado',
      "ativoModified": 'O status foi modificado',
      "modeloModified": 'O modelo foi modificado',
    },
    "configuracaoImpressao": {
      "alturaModified": 'A altura foi modificada',
      "assinaturaVisivelPosicaoXModified": 'A posição X da assinatura foi modificado',
      "assinaturaVisivelPosicaoYModified": 'A posição Y da assinatura foi modificado',
      "larguraModified": 'A largura foi modificada',
      "modeloModified": 'O modelo foi modificado',
      "retratoModified": 'A orientação da impressão foi modificada',
      "tamanhoPapelModified": 'O tamanho do papel foi modificado',
      "tipoModified": 'O tipo foi modificado',
    },
    "configuracaoSecretaria": {
      "ativoModified": 'O status foi modificado',
    },
    "procedimentoOdontologico": {
      "ativoModified": 'O status foi modificado',
      "denteModified": 'O dente foi modificado',
      "mucosaOralModified": 'A mucosa oral foi modificada',
      "observacaoModified": 'A observação foi modificada',
      "agendamentoModified": 'O agendamento foi modificado',
      "procedimentoModified": 'O procedimento foi modificado',
    },
    "pacoteSessoes": {
      "ativoModified": 'O status foi modificado',
      "nomeSessaoModified": 'O nome da sessão foi modificado',
      "observacaoModified": 'A observação foi modificada',
      "sujeitoAtencaoModified": 'O sujeito de atenção foi modificado',
    },
    "sessao": {
      "ativoModified": 'O status foi modificado',
      "agendamentoModified": 'O agendamento foi modificado',
      "controleSessaoModified": 'O controle da sessão foi modificado',
      "salaModified": 'A sala foi modificada',
      "procedimentoModified": 'O procedimento foi modificado',
      "agendamentoTipoModified": 'O tipo de agendamento foi modificado',
    },
    "agendamento": {
      "convenioIdModified": 'O convênio do paciente foi modificado',
      "documentoModified": 'O documento do paciente foi modificado',
      "dataChegadaSujeitoAtencaoModified": 'A data de chegada do paciente foi modificada',
      "dataModified": 'A data do atendimento foi modificada',
      "dataNascimentoModified": 'A data de nascimento do paciente foi modificada',
      "horaFimModified": 'O horário do fim do atendimento foi modificado',
      "horaInicioModified": 'O horário do inicio do atendimento foi modificado',
      "nomeModified": 'O nome do paciente foi modificado',
      "observacaoModified": 'A observação do atendimento foi modificada',
      "pacienteIdModified": 'O paciente do atendimento foi modificado',
      "profissionalSaudeIdModified": 'O profissional de saúde foi modificado',
      "remotoModified": 'O modo de atendimento foi modificado',
      "salaIdModified": 'A sala do atendimento foi modificada',
      "situacaoModified": 'A situação do agendamento foi modificada',
      "solicitanteIdModified": 'O solicitante foi modificado',
      "telefoneModified": 'O telefone do paciente foi alterado.',
      "tipoModified": 'O tipo do atendimento foi modificado.',
      "motivoCancelamentoModified": 'O motivo do cancelamento foi modificado.',
      "convenioNumeroCarteiraModified": 'O numero da carteira do convênio foi modificado',
      "convenioValidadeCarteiraModified": 'A validade da carteira do convênio foi modificada',
      "numeroGuiaTissExternaModified":'O número da guia Tiss Externa foi modificada',
      "agendamentoRecorrenciaIdModified": "A recorrência do agendamento foi alterada.",
      "urgenteModified": "O status do agendamento foi modificado.",
      "listaDeEsperaModified": "O status do agendamento foi modificado."
    },
    "categoriaFinanceiraUnidade": {
      "ativoModified": 'O status foi modificado',
      "nomeModified": 'O nome foi modificado',
      "tipoModified": 'O tipo foi modificado',
    },
    "contaBancariaUnidade": {
      "ativoModified": 'O status foi modificado',
      "nomeModified": 'O nome foi modificado',
      "bancoModified": 'O banco foi modificado',
      "saldoAtualModified": 'O saldo atual foi modificado',
      "saldoInicialModified": 'O saldo inicial foi modificado',
    },
    "guiaInternacaoModelo": {
      "ativoModified": 'O status foi modificado',
      "nomeModified": 'O nome foi modificado',
      "observacaoModified": 'A observação foi modificada',
      "hasOPMEModified": 'A previsão de uso de OPME foi modificado',
      "indicacaoPorModified": 'A indicação foi modificada',
      "profissionalSaudeModified": 'O profissional de saude foi modificado',
      "quimioterapicoModified": 'A previsão de uso de quimioterápico foi modificado',
    },
    "PeriodoAtendimentoModificado": {
      "acaoModified": 'O status de bloqueio do agendamento foi modificado',
      "dataModified": 'A data de bloqueio foi modificada',
      "horaFimModified": 'A hora final do bloqueio do agendamento foi modificado',
      "horarioInicioModified": 'A hora inicial do bloqueio do agendamento foi modificado',

    },
    "profissionalExterno": {
      "ativoModified": 'O status foi modificado',
      "nomeModified": 'O nome foi modificado',
      "numeroConselhoModified": 'O número do conselho foi modificado',
      "ufConselhoModified": 'A uf do conselho foi modificada',
    },
    "statusOrcamento": {
      "ativoModified": 'O status foi modificado',
      "nomeModified": 'O nome foi modificado',
      "tipoModified": 'O tipo foi modificado',
    },
  }

  Object.keys(modificacao).forEach((key) => {
    if (key.endsWith('Modified')) {
      modificacao[key] === true && camposModificados.push(
        withValue ?
          setValueOnAuditMessage(modificacao, mensagens[type][key], key, type, auditChangesValues) :
          mensagens[type][key]
      )
    }
  });

  return camposModificados;
}

const setValueOnAuditMessage = (modificacao, message, field, entity, auditChangesValues) => {
  let valueFieldName = field.replace('Modified', '');
  let value = modificacao[valueFieldName];

  const isMensagemPadrao = mensagemPadrao[entity] && mensagemPadrao[entity].some(item => item === field);

  if (field === 'fotoPerfilModified') {
    return value ? message : 'A foto de perfil foi excluída.';
  }

  if (field === 'ativoModified') {
    value = modificacao[valueFieldName] ? 'ativado' : 'desativado';
    return `${message?.replace('.', '')} para ${value}.`;
  }

  if (field === 'profissionalSaudeModified') {
    value = modificacao.profissionalSaude.nome;
  }

  if (field === 'remotoModified') {
    value = modificacao.remoto ? 'remoto' : 'presencial'
  }

  if(auditChangesValues[field]?.length > 0){
    if(field === 'agendamentoRecorrenciaIdModified'){
      return message
    }
    const valorInicial = auditChangesValues[field][1]
    const valorFinal = auditChangesValues[field][0]
    
    if(!valorInicial){
      return  <p>{message?.replace('.', '')}: <strong>{valorFinal}</strong> foi adicionad{message[0].toLowerCase()}.</p>
    }
    if(!valorFinal){
      return  <p>{message?.replace('.', '')}: <strong>{valorInicial}</strong> foi excluíd{message[0].toLowerCase()}</p>
    }
    return ( <p>{message?.replace('.', '')} de <strong>{valorInicial}</strong> para <strong>{valorFinal}</strong>.</p> )
  }

  if (isMensagemPadrao) {
    return message;
  }

  return value ? `${message?.replace('.', '')} para ${value}.` : `${message[0]} ${entitiesAttributesName[entity][valueFieldName]} foi excluíd${message[0].toLowerCase()}`
}

export default getCamposModificados
