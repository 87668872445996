import React from 'react';
import classnames from "classnames"
import itemStyles from "./ItemMenu.scss"
import {Link} from 'react-router-dom'

import {withStyles} from "@material-ui/core/styles/index";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Badge } from '@material-ui/core';

const style = theme => ({
    root: {
        flex: '1 100%',
        maxHeight: '8.8%',
        margin: '2px 0',
    },

    icon: {
        color: "#fff",
        width: "24px !important",
        height: "24px !important",
    },

    selected: {
        backgroundColor: '#00BBB8',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '16px',
    },

    notification: {
        "& span": {
            width: 16,
            height: 16,
            fontSize: '10px !important',
            borderRadius: 16,
            backgroundColor: "#717FFC",
            minWidth: '16px',
        }
    },
});


const ItemMenu = (props) => {
    const {classes, icon, badgeContent, title, pathname, to, onClick} = props;

    const isSelected = () => {
        if (!pathname) return false;

        let isSelected = to === '/atendimento' ? pathname === to : pathname.includes(to);

        if(to === "/relatorios" || to === "/configuracoes"){
            isSelected = pathname.includes(to.replace("/", ""))
        };

        return isSelected
    } 

    const disabled = pathname === to;

    return (
        <Grid item container alignItems={"center"} justify={"center"} className={classes.root}>
            <Tooltip title={title} placement="right">
            {
                to && !disabled ?
                    <Link to={to} replace className={classnames(
                        itemStyles["btn-menu"], isSelected() && classes.selected
                    )}>
                        {badgeContent > 0 && (
                            <Badge color="secondary" badgeContent={badgeContent > 99 ? "99+" : badgeContent} className={classes.notification}>
                                {icon}
                            </Badge>
                        )}
                        {!badgeContent && icon}
                    </Link> :

                    <div
                        onClick={disabled ? null : onClick}
                        className={classnames(
                            itemStyles["btn-menu"], isSelected() && classes.selected
                        )}>
                        {badgeContent > 0 && (
                            <Badge color="secondary" badgeContent={badgeContent > 99 ? "99+" : badgeContent}>
                                {icon}
                            </Badge>
                        )}
                        {!badgeContent && icon}
                    </div>

            }
            </Tooltip>
        </Grid>
    )


};

export default withStyles(style)(ItemMenu)