import React from "react";
import {inject, observer} from "mobx-react";

import Grid from "@material-ui/core/Grid";
import ItemSubMenu from "../SujeitoAtencao/Manutencao/Menu/ItemSubMenu";
import { isEmpty } from "../../utils/checkObj";
import { checkUserRole } from "../../utils/checkUserRole";
import styled from 'styled-components';
import { getWhitelabelAtual, getWhitelabelNome } from "../../services/WhitelabelService";

const TitleMenu = styled.div`
    font-weight: 700;
    font-size: 10px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #505050;
    height: 23px;
    padding-left: 28px;
    margin-top: ${(props) => props.title === "Perfil Unidade" ? "21px" : 0};
`;

const Notification = styled.div`
    min-width: 15px;
    max-height: 15px;
    min-height: 15px;
    color: #FFFFFF;
    background: #FB7676;
    font-family: Poppins;
    font-weight: 600;
    font-size: 10px;
    display: flex;
    border-radius: 100px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: center;
    margin-right: 8px;
`;

@inject("profissionalSaudeStore", "perfilPublicoStore", "unidadeStore")
@observer
class PerfilSubMenu extends React.Component {
    state = {
        selected: "principal",
        canViewFinanceiro: false,
        canUpdateUnidade: false,
    };

    setSelected = (selected) => {
        this.setState({ selected });
    };

    async componentDidMount() {
        const { match } = this.props;
        let checkSelected = window.location.href.split("/")[7];

        if (isEmpty(match.params)) {
            checkSelected = window.location.href.split("/")[5];
        }

        const canViewFinanceiro = await checkUserRole("ROLE_TITULO_PARCELA_CREATE");
        const canUpdateUnidade = await checkUserRole('ROLE_UNIDADE_UPDATE');
        this.setState({ canViewFinanceiro, canUpdateUnidade });

        switch (checkSelected) {
            case undefined:
                this.setSelected("principal");
                break;

            case "unidade":
                this.setSelected("unidade");
                break;

            case "publico":
                this.setSelected("publico");
                break;

            case "avaliacoes":
                this.setSelected("avaliacoes");
                break;
            case "unidade-vizi": 
                this.setSelected("unidadePerfilVizi");
                break;
            case "recebimentos-profissional":
                this.setSelected("recebimentosProfissional");
                break;
            case "recebimentos-unidade":
                this.setSelected("recebimentosUnidade");
                break;
            default:
                console.log("default");
                break;
        }
        
    }

    render() {
        const { match, perfilPublicoStore } = this.props;
        const { selected, canViewFinanceiro, canUpdateUnidade } = this.state;
        const { profissionalSaudeStore } = this.props;
        const { profissionalSaude } = this.props.profissionalSaudeStore; 
        const { unidade } = this.props.unidadeStore; 
        const { perfilPublicoProfissionalErrors, perfilPublicoProfissional, perfilPublicoUnidade } = perfilPublicoStore;
        const whitelabelNome = getWhitelabelNome();
        const whitelabel = getWhitelabelAtual();

        const errorPerfilPublicoProfissional = Object.values(perfilPublicoProfissionalErrors).filter(item => item.error)
        const hasPerfilPublicoUsuario = perfilPublicoProfissional?.id;
        const hasPerfilPublicoUnidade = perfilPublicoUnidade?.id;

        const showRecebimentosUnidade = canUpdateUnidade && hasPerfilPublicoUnidade && whitelabel?.utilizaPagamentoUnidade && canUpdateUnidade;
        const showRecebimentosProfissional =  canViewFinanceiro && hasPerfilPublicoUsuario;

        let newMatch = [
            {
                title: "Perfil Profissional",
                children: [
                    {
                        id: "principal",
                        name: "Dados do Profissional",
                        to: match.url,
                    },
                    {
                        id: "publico",
                        name: `Perfil ${whitelabelNome} Profissional`,
                        to: `${match.url}/publico`,
                        hidden: !profissionalSaude.perfilPublico,
                        notification: profissionalSaude.utilizaVIZI && !profissionalSaudeStore.isLoadingInfo && errorPerfilPublicoProfissional.length > 0 && <Notification> {errorPerfilPublicoProfissional.length} </Notification>
                    },

                    {
                        id: "recebimentosProfissional",
                        name: "Recebimentos",
                        to: `${match.url}/recebimentos-profissional`,
                        hidden: !showRecebimentosProfissional,
                    },
                    {
                        id: "avaliacoes",
                        name: "Avaliações",
                        to: `${match.url}/avaliacoes`,
                        hidden: !profissionalSaude.utilizaVIZI
                    },
                ]
            },
            {
                title: "Perfil Unidade",
                children: [
                    {
                        id: "unidade",
                        name: "Dados da Unidade",
                        to: `${match.url}/unidade`,
                    },
                    {
                        id: "unidadePerfilVizi",
                        name: `Perfil ${whitelabelNome} Unidade`,
                        to: `${match.url}/unidade-vizi`,
                        hidden: !unidade.perfilPublico,
                    },
                    {
                        id: "recebimentosUnidade",
                        name: "Recebimentos",
                        to: `${match.url}/recebimentos-unidade`,
                        hidden: !showRecebimentosUnidade,
                    }
                ]
            }
        ]

        return (
            <Grid container direction={"column"} wrap={"nowrap"} style={{zIndex: 1}}>
                {newMatch
                    .filter((filter) => {
                        if (isEmpty(match.params)) {
                            return (
                                filter.id !== "prontuario" ||
                                filter.id !== "consultas"
                            );
                        }
                        return true;
                    })
                    .map((match) => (
                            <>
                                <TitleMenu title={match.title}> { match.title } </TitleMenu>
                                {
                                    match.children.map(item => (
                                        !item.hidden && <ItemSubMenu
                                            key={item.id}
                                            to={item.to}
                                            id={item.id}
                                            name={item.name}
                                            hidden={item.hidden}
                                            selected={selected === item.id}
                                            setSelected={this.setSelected}
                                            label={item.label}
                                            notification={item.notification}
                                        />
                                    ))
                                }
                            </>
                        )
                    )}
            </Grid>
        );
    }
}

export default PerfilSubMenu;
