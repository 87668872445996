import React from "react";
import { normalizeProps } from "./utils/normalizeProps";


const OrcamentoEmitidoIcon = (props) => {
  return (
    <svg width="25" 
    height="27" 
    viewBox="0 0 25 27" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg" 
    {...normalizeProps(props)

    }>
      <g filter="url(#filter0_d_8_12)">

        <path d="M6 7C6 5.89543 6.89543 5 8 5H15C16.1046 5 17 5.89543 17 7V16C17 17.1046 16.1046 18 15 18H8C6.89543 18 6 17.1046 6 16V7Z" fill="white" />
        <path d="M15 4.5H8C6.61929 4.5 5.5 5.61929 5.5 7V16C5.5 17.3807 6.61929 18.5 8 18.5H15C16.3807 18.5 17.5 17.3807 17.5 16V7C17.5 5.61929 16.3807 4.5 15 4.5Z" stroke="white" />
      </g>
      <g filter="url(#filter1_d_8_12)">
        <circle cx="15.5" cy="8.5" r="5.5" fill="#00908B" />
        <circle cx="15.5" cy="8.5" r="5.45" stroke="white" stroke-width="0.1" />
      </g>
      <path d="M15.1463 13V4H15.8996V13H15.1463ZM17.5026 6.79141C17.4536 6.45859 17.2515 6.20078 16.8963 6.01797C16.5411 5.83281 16.0941 5.74023 15.5551 5.74023C15.1693 5.74023 14.8355 5.78711 14.5538 5.88086C14.2721 5.97227 14.0531 6.09883 13.897 6.26055C13.7439 6.41992 13.6673 6.60156 13.6673 6.80547C13.6673 6.97656 13.7194 7.12422 13.8235 7.24844C13.9307 7.37266 14.07 7.47695 14.2415 7.56133C14.416 7.64336 14.6028 7.7125 14.8018 7.76875C15.0009 7.82266 15.1923 7.86719 15.376 7.90234L16.2946 8.08516C16.5947 8.14141 16.9024 8.21758 17.2178 8.31367C17.5332 8.40977 17.8257 8.53633 18.0951 8.69336C18.3646 8.85039 18.582 9.04492 18.7474 9.27695C18.9158 9.50898 19 9.78672 19 10.1102C19 10.518 18.8622 10.8801 18.5866 11.1965C18.3141 11.5129 17.9175 11.7625 17.397 11.9453C16.8795 12.1281 16.2533 12.2195 15.5184 12.2195C14.8141 12.2195 14.2047 12.134 13.6903 11.9629C13.1759 11.7918 12.7732 11.5492 12.4823 11.2352C12.1914 10.9187 12.0306 10.5437 12 10.1102H13.4239C13.4514 10.3703 13.5617 10.5871 13.7546 10.7605C13.9506 10.9316 14.2001 11.0594 14.5033 11.1437C14.8095 11.2258 15.1448 11.2668 15.5092 11.2668C15.9103 11.2668 16.2671 11.2187 16.5794 11.1227C16.8948 11.0242 17.1428 10.8883 17.3235 10.7148C17.5042 10.5391 17.5945 10.334 17.5945 10.0996C17.5945 9.88633 17.5149 9.71172 17.3556 9.57578C17.1995 9.43984 16.9867 9.32734 16.7172 9.23828C16.4508 9.14922 16.1492 9.0707 15.8123 9.00273L14.7008 8.7707C13.9475 8.61367 13.3504 8.38281 12.9094 8.07812C12.4716 7.77344 12.2526 7.37031 12.2526 6.86875C12.2526 6.45391 12.3996 6.0918 12.6936 5.78242C12.9875 5.47305 13.3856 5.23281 13.8878 5.06172C14.39 4.88828 14.9565 4.80156 15.5873 4.80156C16.2242 4.80156 16.7861 4.88711 17.273 5.0582C17.7629 5.2293 18.1487 5.46484 18.4304 5.76484C18.7122 6.0625 18.8591 6.40469 18.8714 6.79141H17.5026Z" fill="white" />
      <line x1="7" y1="16.4" x2="15" y2="16.4" stroke="#00908B" stroke-width="1.2" />
      <line x1="7" y1="13.4" x2="13" y2="13.4" stroke="#00908B" stroke-width="1.2" />
      <line x1="7" y1="10.4" x2="10" y2="10.4" stroke="#00908B" stroke-width="1.2" />
      <line x1="7" y1="7.4" x2="9" y2="7.4" stroke="#00908B" stroke-width="1.2" />
      <defs>
        <filter id="filter0_d_8_12" x="1" y="4" width="21" height="23" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8_12" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8_12" result="shape" />
        </filter>
        <filter id="filter1_d_8_12" x="6" y="3" width="19" height="19" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8_12" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8_12" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default OrcamentoEmitidoIcon;