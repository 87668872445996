import React, { useEffect, useState } from "react";
import Notification from "../../components/Notification";
import { inject } from "mobx-react";
import PageTitle from "../../components/PageTitle/PageTitle";
import HeaderFilters from "./HeaderFilters";
import Footer from "../../components/Footer/Footer";
import { filtersDefault, notificationDefault } from "./constants";
import GerenciadorOrcamentosTable from "./GerenciadorOrcamentosTable";
import { findAllOrcamentoOdontologico } from "../../services/GerenciadorOrcamentosService";
import ModalOrcamentoOdontologico from "../Atendimento/Tabs/Odontograma/Modal/Orcamento/ModalOrcamentoOdontologico";
import { findOrcamentoByIdGerenciador } from "../../services/OdontogramaService";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { Button } from "../../components/ui/Buttons";
import PrintIcon from "../../components/Icon/Print";
import DownaloadIcon from "../../components/Icon/DownloadIcon";
import { findAllOrcamentoList, relatorioAllOrcamentoCsv } from "../../services/OrcamentoService";
import { base64Convert } from "../../utils/base64ToCsv";
import ImpressaoHtml from "../../components/Impressao/ImpressaoHtml";
import { withStyles } from "@material-ui/core";
import GerenciadorOrcamentosRelatorio from "../../template/pdf/gerenciadorOrcamentos/GerenciadorOrcamentosRelatorio";

const GerenciadorOrcamentos = observer(({ classes, orcamentoStore, configuracaoImpressaoStore }) => {
  const [notification, setNotification] = useState(notificationDefault);
  const [filters, setFilters] = useState(filtersDefault);
  const [orcamentos, setOrcamentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastScroll, setLastScroll] = useState(false);
  const [pageableDTO, setPageableDTO] = useState({
    pageNumber: 0,
    pageSize: 30,
    sortDir: "DESC",
    sortField: "numero",
  });
  const [openModal, setOpenModal] = useState(false);
  const [isPrintMustache, setIsPrintMustache] = useState(false);
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);
  const [statusBack, setStatusBack] = useState(null);
  const [orcamentoComparar, setOrcamentoComparar] = useState(null);
  const [
    orcamentosPrint,
    setOrcamentosPrint,
  ] = useState([]);

  const {
    statusOrcamentoOdontologico,
    numero,
    profissionalSaude,
    dataHoraLancamentoInicio,
    dataAgendamento,
    sujeitoAtencao,
  } = filters;

  useEffect(() => {
    loadOrcamentos({ isClear: true });
  }, []);

  const openNotification = (message, variant) => {
    setNotification({
      isOpen: true,
      message,
      variant,
    });

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 4000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
      variant: "",
    };
    setNotification(notification);
  };

  const loadOrcamentos = async (options) => {
    try {
      if (loading) return;

      setLoading(true);

      const dataHoraLancamentoInicioMoment = moment(dataHoraLancamentoInicio).format("YYYY-MM-DDTHH:mm:ss");
      const dataAgendamentoMoment = moment(dataAgendamento).format("YYYY-MM-DD");
      const variables = {
        ...(sujeitoAtencao?.id && { sujeitoAtencaoId: sujeitoAtencao.id }),
        ...(statusOrcamentoOdontologico?.id && {
          statusOrcamentoOdontologicoId: statusOrcamentoOdontologico.id,
        }),
        ...(numero && { numero: Number(numero) }),
        ...(dataHoraLancamentoInicio && {
          dataHoraLancamentoInicio: dataHoraLancamentoInicioMoment,
          dataHoraLancamentoFim: dataHoraLancamentoInicioMoment,
        }),
        ...(dataAgendamento && { dataAgendamento: dataAgendamentoMoment }),
        ...(profissionalSaude?.id && {
          profissionalSaudeId: profissionalSaude.id,
        }),
        pageableDTO: {
          ...pageableDTO,
          pageNumber: options?.isClear ? 0 : pageableDTO.pageNumber,
        },
      };

      const response = await findAllOrcamentoOdontologico(variables);
      const { last, content } = response;

      setOrcamentos(options?.isClear ? content : [...orcamentos, ...content]);
      setLastScroll(last);
      setPageableDTO({
        ...pageableDTO,
        pageNumber: options?.isClear ? 1 : pageableDTO.pageNumber + 1,
      });
    } catch (error) {
      openNotification("Erro ao carregar os orçamentos", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectOrcamento = async(orcamentoId) => {
    const response = await findOrcamentoByIdGerenciador(orcamentoId);
    
    setStatusBack(response.statusOrcamentoOdontologico)
    const orcamento = await orcamentoStore.getDadosOrcamentoOdontologico(orcamentoId, response.sujeitoAtencao?.id, response);
    setOrcamentoComparar(orcamento)
    
    setOpenModal(true);
  };

  const handleDownload = async() => {
    const dataHoraLancamentoInicioMoment = moment(dataHoraLancamentoInicio).format("YYYY-MM-DDTHH:mm:ss");
    const dataAgendamentoMoment = moment(dataAgendamento).format("YYYY-MM-DD");

    const variables = {
      ...(sujeitoAtencao?.id && { sujeitoAtencaoId: sujeitoAtencao.id }),
      ...(statusOrcamentoOdontologico?.id && {
        statusOrcamentoOdontologicoId: statusOrcamentoOdontologico.id,
      }),
      ...(numero && { numero: Number(numero) }),
      ...(dataHoraLancamentoInicio && {
        dataHoraLancamentoInicio: dataHoraLancamentoInicioMoment,
        dataHoraLancamentoFim: dataHoraLancamentoInicioMoment,
      }),
      ...(dataAgendamento && { dataAgendamento: dataAgendamentoMoment }),
      ...(profissionalSaude?.id && {
        profissionalSaudeId: profissionalSaude.id,
      }),
    };

    const base64 = await relatorioAllOrcamentoCsv(variables);
    if (base64 === "error") return;
    const blob = base64Convert(base64);
    const url = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "relatorioOrcamentos.csv");
    document.body.appendChild(link);
    link.click();
  };

   const handlePrint = async () => {
      try {
        setIsLoadingPrint(true);
        await configuracaoImpressaoStore.getConfig("RELATORIO");

        const dataHoraLancamentoInicioMoment = moment(dataHoraLancamentoInicio).format("YYYY-MM-DDTHH:mm:ss");
        const dataAgendamentoMoment = moment(dataAgendamento).format("YYYY-MM-DD");

        const variables = {
          ...(sujeitoAtencao?.id && { sujeitoAtencaoId: sujeitoAtencao.id }),
          ...(statusOrcamentoOdontologico?.id && {
            statusOrcamentoOdontologicoId: statusOrcamentoOdontologico.id,
          }),
          ...(numero && { numero: Number(numero) }),
          ...(dataHoraLancamentoInicio && {
            dataHoraLancamentoInicio: dataHoraLancamentoInicioMoment,
            dataHoraLancamentoFim: dataHoraLancamentoInicioMoment,
          }),
          ...(dataAgendamento && { dataAgendamento: dataAgendamentoMoment }),
          ...(profissionalSaude?.id && {
            profissionalSaudeId: profissionalSaude.id,
          }),
        };
  
        const response = await findAllOrcamentoList(variables);
  
        setOrcamentosPrint(response);
  
        setIsPrintMustache(true);
      } catch (error) {
        openNotification("Erro ao imprimir o relatório de orçamentos", "error");
      } finally {
        setIsLoadingPrint(false);
      }
    };

  return (
    <div className={classes.container}>
      <PageTitle title="Gerenciador de Orçamentos" />
      <HeaderFilters
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        loadOrcamentos={loadOrcamentos}
      />
      <div className={classes.content}>
        <GerenciadorOrcamentosTable
          orcamentos={orcamentos}
          lastScroll={lastScroll}
          loadOrcamentos={loadOrcamentos}
          handleSelectOrcamento={handleSelectOrcamento}
          loading={loading}
        />
      </div>
      {openModal && <ModalOrcamentoOdontologico
        openModalOrcamento={openModal}
        setOpenModalOrcamento={setOpenModal}
        loadList={() => loadOrcamentos({ isClear: true })}
        sujeitoAtencaoSelected={orcamentoStore.orcamento.sujeitoAtencao}
        statusBack={statusBack}
        orcamentoComparar={orcamentoComparar}
      />}
      <div className={classes.containerButtons}>
        <Button
          bgColor="#FBBF6D"
          onClick={handlePrint}
          shape="circle"
          disabled={isLoadingPrint}
        >
          <PrintIcon/>
        </Button>
        <Button
          bgColor="#FBBF6D"
          onClick={handleDownload}
          shape="circle"
          disabled={isLoadingPrint}
        >
          <DownaloadIcon/>
        </Button>
      </div>
      {isPrintMustache && (
        <ImpressaoHtml
          isPrintMustache={isPrintMustache}
          handlePrintMustache={() => setIsPrintMustache(false)}
          htmlStringComponent={
            <GerenciadorOrcamentosRelatorio
              dadosRelatorio={orcamentosPrint || []}
              filters={filters}
            />
          }
        />
      )}
      <Notification
        close={() => solicitacoesPacientesStore.closeAlertMessage()}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
      <Footer />
    </div>
  );
});

const styles = {
  container: {
    background: "#f2f2f2",
    width: "100%",
  },
  content: {
    height: "calc(100% - 205px)",
    margin: "24px 24px 0 24px",
    borderRadius: "16px",
    background: "#fff",
    overflowY: "auto",
  },
  containerButtons: {
    display: "flex",
    gap: "8px",
    position: "absolute",
    right: "37px",
    bottom: "88px",
  }
};

const GerenciadorOrcamentosWithStyles = withStyles(styles)(
  GerenciadorOrcamentos
);
export default inject("orcamentoStore", "configuracaoImpressaoStore")(GerenciadorOrcamentosWithStyles);
