import Colors from "../../template/Colors";
import { withStyles } from "@material-ui/core";
import ItemGrid from "../../components/Modal/ItemGrid";
import DialogTitle from "../../components/Dialog/DialogTitle";

export const styles = () => ({
  root: {
    display: "block",
    error: {
      border: 0,
    },
  },
  container: {
    overflow: "hidden",
    borderRadius: 10,
    "&> div": {
      flexBasis: 'auto'
    }
  },
  spaceRight: {
    paddingRight: "10px",
    lineHeight: "15px",
  },
  spaceRightSelect: {
    paddingRight: "10px",
    paddingLeft: "2px",
  },
  spaceLeftSelect: {
    paddingLeft: "2px",
  },
  spaceTop: {
    paddingTop: "12px",
    flexBasis: "12%",
  },
  spaceTopButtons: {
    padding: "10px 0 10px 0px",
  },
  spaceTopColumns: {
    paddingTop: "10px",
  },
  spaceTopObs: {
    paddingTop: "10px",
  },
  inlineButtonLast: {
    display: "inline-flex",
    width: "100%",
    maxWidth: "28%",
    justifyContent: 'center',
  },
  inlineButtonLastIsStatusBloqueado: {

    display: "inline-flex",
    width: "100%",
    maxWidth: "29.5%",
  },
  inlineButtons: {
    paddingRight: "15px",
    display: "inline-flex",
    minWidth: '100px',
  },
  modalArrow: {
    height: "35px",
    marginTop: "10px",
    paddingLeft: "30px",
    cursor: "pointer",
  },
  gridFather: {
    display: "flex",
    overflowY: "hidden",
    padding: 0,
    maxHeight: "625px",
    height: "100vh",
    paddingTop: "0px !important",
  },
  gridFatherBloqueado: {
    height: "auto",
  },
  gridFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  gridFlexNaoBloqueado: {
    maxWidth: 505,
  },
  paperListTitle: {
    flexBasis: "18%",
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: 700,
    color: Colors.commons.padrao,
  },
  paperList: {
    maxHeight: "335px",
    overflow: "auto",
    boxShadow: "none",
  },
  listPadding: {
    paddingRight: "16px",
  },
  listItemMargin: {
    marginBottom: "0px",
  },
  listItem: {
    border: "1px solid " + Colors.commons.gray3,
    borderRadius: "15px",
    padding: "14px",
    marginBottom: "10px",
    cursor: "pointer",
  },
  listItemSelected: {
    border: "1px solid " + Colors.primary.main,
    borderRadius: "15px",
    padding: "10px",
    marginBottom: "10px",
    cursor: "pointer",
  },
  paperWidthMd: {
    maxWidth: "835px",
    minHeight: "625px",
    backgroundColor: "#F9F9F9",
    overflow: "visible",
    borderRadius: 10,
  },
  paperWidthSm: {
    minHeight: 'auto',
  },
  header: {
    position: "relative",
    boxShadow:
      "15px 15px 35px rgba(112, 124, 151, 0.05), 10px 10px 25px rgba(112, 124, 151, 0.05)",
  },

  boxInfosSujeitoAtencao: {
    background: "#FFFFFF",
    padding: '11px 0px 6px 20px'
  },

  boxAgendamento: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    background: "#F9F9F9",
    height: "100%",
    borderRadius: "0px 0px 0px 10px",
    padding: "9px 0px 2px 20px",
    overflow: "hidden",
  },

  notchedOutline: {
    border: "0",
  },
  inputTextField: {
    fontSize: "14px",
    border: "0",
    minHeight: "6px",
    height: 35,
    color: Colors.commons.gray9,
    backgroundColor: Colors.commons.gray2,
    margin: "0 0 0 0",
    borderRadius: "8px",
    fontFamily: "Poppins",
  },
  actionButtons: {
    background: "#F9F9F9",
    display: "flex",
    padding: "0px 0px 0px 20px",
    justifySelf: "flex-end"
  },

  boxBloqueado: {
    padding: "16px 40px 12px 40px",
    background: "#f9f9f9",
    borderRadius: "0px 0px 0px 10px",
    maxWidth: "530px",
  },

  observacao: {
    backgroundColor: "#F2F2F2",
    width: "98% !important",
    borderRadius: "5px",
    padding: "0px 5px",
    border: 0,
    fontSize: '14px',
    color: Colors.commons.gray7,
    "&>div":{
      "&>textarea":{
        height: "100%",
        borderRadius: 0
      }
    }
  },

  radioButton: {
    display: 'flex',
    margin: '5px 0px',
    "&> span":{
      fontSize: "10px !important",
      padding: '1px 3px 0 0',
      color: Colors.commons.gray7
    },
    "&> input": {
      padding: '0px !important'
    },
    "& svg": {
      height: 20,
      width: 20,    
    },
  },

  dateTime: {
    width: "100%",
    fontFamily: "Poppins !important",
    fontSize: 12,
    "&> div": {
      borderRadius: 8,
    },
    "& input": {
      color: "#505050",
      fontWeight: 500,
    },
  },

  btnActionAdd: {
    position: "absolute",
    right: 5,
    padding: "13px 10px",
    zIndex: 10,
  },

  btnAddEditMini: {
    minHeight: 24,
    height: 24,
    width: 24,
    backgroundColor: Colors.commons.gray10,
    boxShadow: "none",
    color: "#DADADA",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: Colors.commons.darkGray
    },

    "& svg": {
      fontSize: "18px",
      color: "#FFFFFF",
    },
  },
  procedimentoOption: {
    background: "#F2F2F2",
    border: "0.5px solid #BDBDBD",
    borderRadius: "100px",
    minWidth: "80px",
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 8px",
    margin: "3px",
    "& svg": {
      color: "#868686",
      marginLeft: "5px",
    },
  },
  listaProcedimentosSelecionados: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  selectSearch: {
    width: "100%",
    height: 35,
    "&> div > div": {
      borderRadius: 8,
      maxHeight: '32px',
    }
  },
  selectSearchSemAltura: {
    width: "100%",
  },
  btnEraseMini: {
    minHeight: 24,
    height: 24,
    width: 24,
    backgroundColor: "#FB7676",
    boxShadow: "none",
    color: "#DADADA",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: Colors.commons.darkRed
    },

    "& svg": {
      fontSize: "16px",
      color: "#FFFFFF",
    },
  },
  inputInfosTelefone: {
    color: "#505050",
    width: '100%',
  },
  telefoneWhatsApp: {
    backgroundColor: "#f2f2f2",
    height: 32,
    width: '100%',
    display: "flex",
    alignItems: "center",
    borderRadius: 100,
    '&>div':{
      width: "100%"
    }
  },
  gridButtons: {
    height: "55px",
    alignContent: "center",
    placeItems: 'center',
  },
  divButtons: {
    display: "flex",
    position: "absolute",
    top: 10,
    right: 15,
    zIndex: 10,
    alignItems: "center",
  },
  btnHeader: {
    padding: "10px 3px",
  },
  btnHeaderWithMargin: {
    marginRight: "4px"
  },
  selectTipo: {
    maxHeight: 35,
    height: 35,
  },
  inputInfos: {
    color: "#505050",
    borderRadius: '8px',
  },
  dateNascimento: {
    width: '100%',
  },
  inputInfosDuracaoSituacao: {
    color: "#505050",
    paddingLeft: 15,
  },
  heightSearch: {
    maxHeight: 32,
  },
  inputInfosConvenio: {
    color: "#505050",
    borderRadius: '8px',
    "&:focus": {
      color: "#505050",
    },
  },
  inputDadosAgendamento: {
    height: 32,
  },
  subTitle:{
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: 700,
    color: Colors.commons.padrao,
    marginTop: 5
  },
  iconTimeDadosAgendamento:{
    '& button': {
      backgroundColor: 'initial',
      "&:disabled": {
        backgroundColor: Colors.commons.gray8
    }
    },
    '& svg':{
      fill: `${Colors.commons.gray7} !important`
    }
  },
  buttonsConfirmarTransferir:{
    width: '75%'
  },
  buttonCancel: {
    color: Colors.commons.gray9,
    cursor: 'pointer',
    height: 20,
    fontWeight: 700
  },
  buttonListaDeEspera: {
    backgroundColor: '#F2F2F2',
    '& svg': {
      color: '#505050'
    },
    "&:hover": {
      '& svg': {
        color: '#FFF'
      },
      backgroundColor: '#717FFC !important'
    },
  },
  buttonListaDeEsperaChecked: {
    '& svg': {
      color: '#FFF !important'
    },
  },
  buttonUrgente: {
    backgroundColor: '#F2F2F2',
    marginLeft: '4px',
    '& svg': {
      color: '#FB7676',
    },
    "&:hover": {
      backgroundColor: 'rgba(247, 207, 207, 1) !important',
    },
  },
  rowSubTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '15px',
  },
  buttonRecorrencia:{
    display:'flex',
    background: Colors.commons.gray10,
    height: 22,
    color: Colors.commons.white,
    borderRadius: 100,
    width: 87,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  labelButtonRecorrencia:{
    fontSize: '10px !important',
    fontWeight: 700,
    marginLeft: '3px',

  },
  auditoriaHeader: {
    display: 'flex',
    flexDirection:'row',
    justifyContent: 'space-between',
    margin: '16px 16px 4px 0',
    alignItems: 'center',
    '& span': {
      color: '#505050',
      fontWeight: '600',
      fontSize: '14px'
    }
  },
  auditoriaBody: {
    display: 'flex',
    height: '344px',
    width:'100%',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  profissionalProfileImage: {
    borderRadius: '50%'
  },
  auditoriaText: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginRight: '16px',
  },
  auditoriaItem: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '8px',
    margin: '0 16px 12px 0',
  },
  auditoriaItemInformacao: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    width: '100%',
    padding: '6px',
    borderRadius: '4px',
    '& p': {
      margin: 0,
      fontSize: '12px',
      color: '#505050'
    },
    '& ul': {
      margin: 0,
      '& li': {
        color: '#505050',
        fontSize: '12px',
        wordBreak: 'break-all'
      }
    }
  },
  auditoriaItemData: {
    color: '#ADADAD',
    margin: '8px 0 0 0',
    padding: 0,
    fontSize: '8px',
  }, 
  modalContent: {
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  modalContentOutros: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingTop: 12,
    height: '100%'
  },
  modalContentProcedimento: {
    width: '100%'
  },
  botaoCopiarLink:{
    width: '22px',
    height: '20px',
    borderRadius: '100px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '5px',
    "&:hover": {
			background: Colors.commons.gray8,
    } 
  },
  inputComBotao: {
    backgroundColor: "#f2f2f2",
    height: 32,
    width: '100%',
    display: "flex",
    alignItems: "center",
    borderRadius: 100,
  },
  inputInfosLink: {
    width: '100%'
  },
  selectSala: {
    width: '100%',
    "&> div > div": {
      borderRadius: '8px',
    },
  },
  dadosAfericaoVitalIcon: {
    height: 12
  },
  itemGridObservacoes: {
    paddingBottom: '5px',
  },
  searchDuracao :{
    width: '100%',
    "&> div > div": {
      borderRadius: 8,
      maxHeight: 32,
    }
  },
  menuTipo: {
    zIndex: 1
  },
  contentOpcoesTipo: {
    display: 'flex'
  },
  consultaObservacoes: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 1,
  },
  telefoneInputContainer: {
		display: 'flex',
		flexDirection: 'row',
		columnGap: '4px !important',
	},
	telefoneInput: {
    height: '32px !important',
		borderRadius: '8px !important',
    backgroundColor: '#F2F2F2 !important',
    padding: '6px 0px 6px 48px !important',
    width: '100% !important',
    border: 'none !important'
	},
  flagButton: {
    border: 'none !important',
    borderRadius: '100px !important',
    '$:hover': {
      backgroundColor: '#F2F2F2'
    }
  },
  menuGuiaServico: {
    maxHeight: '20vh',
    "&> div": {
      maxHeight: '20vh',
    }
  },
  inputMask: {
    borderRadius: '100px',
    background: '#eeeeee',
    border: 0,
    fontSize: '14px',
  },
  selectInputDocumentoTipo: {
    width: '100%',
    "&>div>div": {
      height: 32,
    }
  },
  textFieldError: {
    border: `1px solid ${Colors.commons.red} !important`
  },
  btnCloseStatusBloqueado: {
    minHeight: 32,
    height: 32,
    width: 32,
    backgroundColor: Colors.commons.secondary,
    boxShadow: "none",
    color: "#DADADA",
    borderRadius: "50%",
    position: "absolute",
    top: 10,
    right: 16,
    "&:hover": {
      backgroundColor: Colors.primary.dark
    },

    "& svg": {
      fontSize: "18px",
      color: "#FFFFFF",
    },
  },
  urgentCheckbox: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "6px",
    padding: "5px",
    "& svg": {
      fontSize: "18px",
    },
  },
  urgentCheckboxLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    "& svg": {
      fontSize: "12px",
    },
  },
  urgentCheckboxLabel: {
    color: "#505050",
    marginRight: "4px",
    userSelect: 'none', 
    fontSize: "10px !important",
  },
  attentionButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FB7676",
    fontSize: "14px",
    borderRadius: "50%",
    padding: "1px",
    color: "#FFF",
    height: '14px',
    width: '14px',
  },
  guiaIcon: {
    height: 16,
  },
  buttonYellow: {
    backgroundColor: Colors.commons.yellow2,
    "&:hover": {
      backgroundColor: Colors.commons.darkYellow
    },
  },
  tooltip: {
    padding: '12px',
    backgroundColor: '#505050',
  },
  notchedOutlineSearch: {
    border: "none",
  },
  inputSearch: {
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  contentConvenio: {
    display: 'flex',
  },
  inputDate: {
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    width: "100%",

    "& > div": {
      border: "none",
    },
    "& input": {
      textAlign: "center",
      fontFamily: "Poppins",
    },
  },
  notificationAgendamentoRetorno: {
    display: 'flex',
    alignItems: 'center',
    background: '#FCF0DF',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    gap: '4px',
    padding: '8px',

    '& span': {
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '14px',
    }
  },
  inputNumeroGuia: {
    height: '22px',
  },
  rootInputGuia: {
    width: "100%",
  },
  selectProcedimento: {
    width: '100%',
    "&>div": {
      maxHeight: 32,
      "&>div": {
        color: '#868686 !important',
        fontWeight: '200'
      }
    }
  }
});

export const StyledItemGridIsConsultaRemota = withStyles({
  item: {
    alignItems: 'center',
    paddingRight: "0px!important",
    paddingBottom: "10px!important",
  },
  itemTitle: {
    color: Colors.commons.gray7,
    fontSize: "12px",
  },
})(ItemGrid);

export const StyledItemGridListaNegra = withStyles({
  item: {
    padding: "0px!important",
    marginBottom: '4px'
  },
  itemTitle: {
    fontWeight: "900",
    fontSize: "12px",
  },
  itemValue: {
    color: Colors.commons.gray7,
    borderBottom: "0px",
    height: "14px",
    margin: 0
  },
})(ItemGrid);

export const StyledWarning = withStyles({
  root: {
    width: "450px",
    backgroundColor: "#F9BE73",
    borderRadius: "30px",
    margin: "0px",
    display: 'flex',
    padding: "0 10px",
  },

  title: {
    color: Colors.commons.gray9,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "Poppins",
    margin: 0,
  },
})(DialogTitle);
