import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TabPagamento from "../../TabPagamento";
import TabObservacoes from "../../TabObservacoes";
import CloseIcon from "../../../../../../../../components/Icon/Close";
import { Button } from "../../../../../../../../components/ui/Buttons";
import PrintIcon from "../../../../../../../../components/Icon/Print";
import { checkUserRoles } from "../../../../../../../../components/RoleProtection";
import CopyIcon from "../../../../../../../../components/Icon/CopyIcon";
import { copiarOrcamento, getStatusOrcamentoPadraoOdontologico } from "../../../../../../../../services/OrcamentoService";
import MultiToggleButtons from "../../../../../../../../components/Button/MultiToggleButtons";
import Dialog from "../../../../../../../../components/Dialog/Dialog";
import { findConfiguracaoUnidade } from "../../../../../../../../services/OdontogramaService";
import {
  defaultErrors,
  orcamentoDefault,
} from "../../../../../../../../stores/Orcamento.store";
import colors from "../../../../../../../../template/Colors";
import HeaderOdontologico from "./HeaderOdontologico";
import TabHistorico from "./TabHistorico";
import { tipoProfissionalSaude } from "../../../../../../../../utils/constants/tipoProfissionalSaude";
import { withRouter } from "react-router-dom";

const tabs = {
  PAGAMENTO: 0,
  OBSERVACOES: 1,
  HISTORICO: 2,
};

const ModalOrcamento = observer(
  ({
    classes,
    open,
    title,
    orcamentoStore,
    children,
    handleSave,
    onClose,
    isLoadingSave,
    setIsLoadingSave,
    handlePrint,
    sujeitoAtencaoSelected,
    statusBack,
  }) => {
    const { orcamento, handleFieldModal } = orcamentoStore;
    const { statusOrcamentoOdontologico } = orcamento || {};

    const [tabSelected, setTabSelected] = useState(tabs.PAGAMENTO);
    const [configUnidade, setConfigUnidade] = useState(null);

    const profissionalSaudeLogado = JSON.parse(localStorage[`_immortal|profissional_saude_logado`] || null);

    const isAdministrador = tipoProfissionalSaude[profissionalSaudeLogado?.tipo] === "Administrador";
    const isStatusDisabled = statusBack?.tipo === "REPROVADO" || statusBack?.tipo === "APROVADO";

    useEffect(() => {
      getConfigUnidade();
      orcamentoStore.errors = defaultErrors;
      !orcamento?.id && getStatusPadraoOdontologico();
    }, []);

    const getStatusPadraoOdontologico = async () => {
      const statusPadraoOdontologico = await getStatusOrcamentoPadraoOdontologico();
      orcamentoStore.orcamento = {
        ...orcamento,
        statusOrcamentoOdontologico: statusPadraoOdontologico,
      };
    };

    const changeTabSelected = (tabSelected) => {
      setTabSelected(tabSelected);
    };

    const getConfigUnidade = async () => {
      const configUnidade = await findConfiguracaoUnidade();

      setConfigUnidade(configUnidade);
    };

    const handleAction = async (action) => {
      try {
        setIsLoadingSave(true);
        await handleSave(action);
      } catch (e) {
        throw e;
      } finally {
        setIsLoadingSave(false);
      }
    };

    const renderTab = () => {
      const tabsOrcamento = {
        0: (
          <TabPagamento
            configUnidade={configUnidade}
            orcamento={orcamento}
            handleFieldModal={handleFieldModal}
          />
        ),
        1: (
          <TabObservacoes
            orcamento={orcamento}
            handleFieldModal={handleFieldModal}
          />
        ),
        2: (
          <TabHistorico
            orcamento={orcamento}
            handleFieldModal={handleFieldModal}
          />
        ),
      };

      return tabsOrcamento[tabSelected];
    };

    const permiteSalvar = async () => {
      const possuiPermissao = await checkUserRoles([
        "ROLE_ORCAMENTO_CREATE",
        "ROLE_ORCAMENTO_UPDATE",
      ]);

      const possuiTitulo = orcamento?.titulo?.id

      return possuiPermissao && !possuiTitulo;
    };

    const handleCopy = async () => {
      try {
        setIsLoadingSave(true);
        const response = await copiarOrcamento(orcamento.id);

        orcamentoStore.getDadosOrcamentoOdontologico(
          response.id,
          sujeitoAtencaoSelected?.id || orcamento?.sujeitoAtencao?.id,
          response || orcamentoDefault
        );
      } catch (e) {
        throw e;
      } finally {
        setIsLoadingSave(false);
      }
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.dialog,
        }}
      >
        <div className={classes.header}>
          {title}
          <div className={classes.buttonsHeader}>
            {orcamento?.id && (
              <Button
                bgColor={"#FBBF6D"}
                onClick={handleCopy}
                disabled={isLoadingSave || !permiteSalvar()}
                shape="circle"
              >
                <CopyIcon />
              </Button>
            )}
            <Button
              className={classes.buttonImprimir}
              onClick={() => handlePrint()}
              disabled={isLoadingSave}
            >
              <PrintIcon />
            </Button>
            <Button bgColor="#FB7676" shape="circle" onClick={onClose}>
              <CloseIcon />
            </Button>
          </div>
        </div>
        <div className={classes.content}>
          <HeaderOdontologico
            sujeitoAtencao={sujeitoAtencaoSelected || orcamento?.sujeitoAtencao}
            orcamento={orcamento}
            handleFieldModal={handleFieldModal}
          />
          <MultiToggleButtons
            options={["Pagamento", "Observações", "Histórico"]}
            tabSelected={tabSelected}
            changeTabSelected={changeTabSelected}
            disabled={!orcamento?.id}
            posicaoDesabilitada={[2]}
          />
          {renderTab()}
          {tabSelected !== tabs.HISTORICO && children}
        </div>
        <div className={classes.buttons}>
            <Button
              bgColor="#707C97"
              onClick={() => handleAction(statusOrcamentoOdontologico?.tipo)}
              width="205px"
              disabled={
                !permiteSalvar() || isLoadingSave || (!isAdministrador && isStatusDisabled) || tabSelected === tabs.HISTORICO
              }
              isLoading={isLoadingSave}
            >
              Salvar
            </Button>
        </div>
      </Dialog>
    );
  }
);

const styles = {
  dialog: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "16px 32px 32px 32px",
    width: "100%",
    maxWidth: "600px",
    height: "100%",
    maxHeight: "640px",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "700",
    color: "#333333",
  },
  content: {
    height: "100%",
    maxHeight: "calc(100% - 113px)",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding:"5px"
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  contentField: {
    display: "flex",
    flexDirection: "column",
  },
  titleField: {
    color: "#868686",
  },
  buttonImprimir: {
    backgroundColor: colors.commons.secondary,
    width: "40px",
    height: "40px",
    color: "white",
    "&:hover": {
      background: colors.primary.dark,
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px !important",
  },
  buttonsHeader: {
    display: "flex",
    gap: "10px",
  },
};

const ModalOrcamentoWithStyles = withRouter(withStyles(styles)(ModalOrcamento));
export default inject(
  "odontogramaStore",
  "prontuarioStore",
  "orcamentoStore"
)(ModalOrcamentoWithStyles);
