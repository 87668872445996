import React from "react";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import menuStyles from "./Menu.scss";
import LogoMenuIcon from "../../components/Icon/LogoMenu";
import AgendaIcon from "../../components/Icon/Agenda";
import AtendimentoIcon from "../../components/Icon/Atendimento";
import PacientesIcon from "../../components/Icon/Pacientes";
import ChatWithStatusIcon from "../../components/Icon/ChatWithStatusIcon";
import FinanceiroMenuIcon from "../../components/Icon/FinanceiroMenu";
import Print from "../../components/Icon/Print";
import ConfiguracoesIcon from "../../components/Icon/Configuracoes";
import ItemMenu from "./ItemMenu/ItemMenu";
import Grid from "@material-ui/core/Grid";
import { inject, observer } from "mobx-react";
import SolicitacoesPacientesIcon from "../../components/Icon/SolicitacoesPacientes";
import { ROLE_PROTECTION_CONDITIONS, RoleProtection } from "../../components/RoleProtection";
import AUTHORITIES from "../../config/authorities";
import GuiaIcon from "../../components/Icon/GuiaIcon";
import { linkLoginAzulControle } from "../../services/FinanceiroService";
import OrcamentoEmitidoIcon from "../../components/Icon/OrcamentoEmitidoIcon";

const TIME_TO_REFRESH = 5 * 60 * 1000;
@inject("chatStore", "socketStore", "solicitacoesPacientesStore", "unidadeStore")
@observer
class Btn extends React.Component {
  constructor(props) {
    super(props)
    this.props.solicitacoesPacientesStore.getQuantidadeSolicitacoesPaciente();
  }

  async componentDidMount() {
    const { solicitacoesPacientesStore } = this.props;
    const profissionalSaudeAtual = JSON.parse(localStorage[`_immortal|profissional_saude_logado`] || null)
    if (profissionalSaudeAtual?.possuiAgenda && profissionalSaudeAtual.utilizaVIZI) {
      setInterval(() => {
        solicitacoesPacientesStore.getQuantidadeSolicitacoesPaciente();
      }, TIME_TO_REFRESH);
    }
  };

  getUnidadeLogada = () => {
    const unidadeLogada = localStorage['_immortal|unidadeLogada'] ? JSON.parse(localStorage['_immortal|unidadeLogada']) : null;
    return unidadeLogada;
  }
  
  possuiUnidadeLogada = async () => {
    const unidadeLogada = await JSON.parse(
      localStorage[`_immortal|unidadeLogada`] || null
    )
    return !!unidadeLogada
  }

  renderLogo = (href, style) => {
    return (
      <Link to={href}>
        <Grid item className={style.logo}>
          <LogoMenuIcon color='#fff' />
        </Grid>
      </Link>
    )
  }

  render() {
    const { chatStore, socketStore, location, solicitacoesPacientesStore, unidadeStore } = this.props;
    const { configuracaoUnidade } = unidadeStore;
    const style = {
      logo: classnames(menuStyles.logo),
      navbar: classnames(menuStyles.navbar),
    }
    const profissionalSaudeAtual = JSON.parse(
      localStorage[`_immortal|profissional_saude_logado`] || null
    )

    const renderLogo = href => {
      return this.renderLogo(href, style)
    }

    const handleOpenFinanceiro = async() => {
      try {
        const linkAzulControle = await linkLoginAzulControle();
        window.open(linkAzulControle);

      } catch(e) {
        console.error(e)
      }
    };

    const isOdontologia = profissionalSaudeAtual?.especialidades.some(especialidade => especialidade?.especialidade === "Odontologia");
    
    return (
      <Grid
        container
        direction={'column'}
        wrap={'nowrap'}
        className={classnames(menuStyles.nav)}
      >
        <RoleProtection
          roles={[AUTHORITIES.ROLE_AGENDAMENTO_READ]}
          fallback={renderLogo('/')}
        >
          {renderLogo(
            profissionalSaudeAtual?.tipo === 4 ? '/atendimento' : '/'
          )}
        </RoleProtection>

        <Grid
          item
          container
          xs={12}
          direction={'column'}
          className={style.navbar}
        >
          <ItemMenu
            title='Agenda'
            to={'/atendimento'}
            icon={<AgendaIcon color='#fff' />}
            pathname={location.pathname}
          />

          {profissionalSaudeAtual?.possuiAgenda && (
            <ItemMenu
              title='Atendimento'
              to={'/atendimento/prontuario'}
              icon={<AtendimentoIcon color='#fff' />}
              pathname={location.pathname}
            />
          )}

          <ItemMenu
            title='Pacientes'
            to={'/sujeitos-de-atencao'}
            icon={<PacientesIcon color='#fff' />}
            pathname={location.pathname}
          />
          {isOdontologia && <ItemMenu
            title='Gerenciador de orçamentos'
            to={'/gerenciador-orcamentos'}
            icon={<OrcamentoEmitidoIcon color='#fff' />}
            pathname={location.pathname}
          />}
          { profissionalSaudeAtual?.possuiAgenda && profissionalSaudeAtual.utilizaVIZI &&
            <ItemMenu
              title="Solicitações de pacientes"
              to={"/solicitacoes-de-pacientes"}
              icon={<SolicitacoesPacientesIcon color="#fff"/>}
              pathname={location.pathname}
              badgeContent={solicitacoesPacientesStore.quantidadeTotalSolicitacoes}
            />
          }
          <ItemMenu
            title='Chat'
            to={'/chat'}
            icon={<ChatWithStatusIcon color={socketStore.isConnectionOpen ? '#78E378' : '#FB7676'} />}
            badgeContent={chatStore?.unreadMessages || 0}
            pathname={location.pathname}
          />
          {profissionalSaudeAtual?.tipo !== 4 && (
            <>
              {(configuracaoUnidade?.azulControleAtivado && !configuracaoUnidade?.utilizaFinanceiroAppHealth) ?
                <ItemMenu
                  title='Financeiro'
                  icon={<FinanceiroMenuIcon color='#fff' />}
                  to={''}
                  onClick={handleOpenFinanceiro}
                />
              :
                <ItemMenu
                  title='Financeiro'
                  to={'/financeiro'}
                  icon={<FinanceiroMenuIcon color='#fff' />}
                  pathname={location.pathname}
                />
              }
            <ItemMenu
              title='Guias'
              to={'/guias/guia-de-consulta'}
              icon={<GuiaIcon color='#fff' />}
              pathname={location.pathname}
            />

              {this.possuiUnidadeLogada() && (
                <RoleProtection
                  roles={[
                    AUTHORITIES.ROLE_SUJEITO_ATENCAO_RELATORIO,
                    AUTHORITIES.ROLE_AGENDAMENTO_RELATORIO,
                  ]}
                  condition={ROLE_PROTECTION_CONDITIONS.ANY}
                >
                  <ItemMenu
                    title='Relatórios'
                    to={'/relatorios/agendamentos'}
                    icon={<Print color='#fff' />}
                    pathname={location.pathname}
                  />
                </RoleProtection>
              )}

              <ItemMenu
                title='Configurações'
                to={'/configuracoes'}
                icon={<ConfiguracoesIcon color='#fff' />}
                pathname={location.pathname}
              />
            </>
          )}
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(Btn)
