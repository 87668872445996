import { Checkbox } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import string from "../../../../../../utils/string";
import InputMaskCurrency from "../../../../../../components/Input/InputMaskCurrency";
import { ReactSelect } from "../../../../../../components/Select/SelectSearch";
import { statusOptions } from "../../../../../SujeitoAtencao/Manutencao/ProcedimentosOdontologicos/constants";

export const tiposModalOrcamento = [
  {
    label: "À vista",
    value: "A_VISTA",
  },
  {
    label: "A prazo",
    value: "A_PRAZO",
  },
];

export const tiposDescontoModalOrcamento = [
  {
    label: "Percentil",
    value: "PERCENTIL",
  },
  {
    label: "Valor",
    value: "VALOR",
  },
];

const siglaFace = [{
  label: "DISTAL",
  value: " D "
},
{
  label:"OCLUSAL_INCISAL",
  value:" O/I "
},
{
  label:"PALATINA_LINGUAL",
  value:" P/L "
},
{
  label:"MESIAL",
  value:" M "
},
{
  label:"VESTIBULAR",
  value:" V "
},
]

export const concatNomelocal =(face,local)=>{
  const siglaFaces = face?.map((item)=> {
    const face = siglaFace.find((face)=>face.label === item)
    return face.value 
  })  
  return siglaFaces?.length > 0 ? `${local + " - face" + siglaFaces.join(" - ")}` : local
}

const tableStatusStyle = {
  width: "100px",
};

export const COLUMNS = ({
  handleCheckAll,
  allCheckBoxChecked,
  handleChangeCheck,
  handleValor,
  isDisabled,
  errors,
  handleStatus,
  orcamento,
}) => [
  {
    Header: (
      <Checkbox
        color="primary"
        checked={allCheckBoxChecked()}
        onChange={() => handleCheckAll()}
        disabled={isDisabled}
      />
    ),
    isClick: true,
    getValue: (row) => {
      const { checked } = row;
      return (
        <Checkbox
          color="primary"
          checked={checked}
          onChange={() => handleChangeCheck(row)}
          disabled={isDisabled}
        />
      );
    },
  },
  {
    Header: "Procedimento",
    align: "start",
    getValue: (row) => string.truncate(row.procedimento?.nome, 35),
  },
  {
    Header: "Local",
    align: "start",
    getValue: (row) => {
      const face = row.face
      const local = row.dente
        ? row.dente.codigo
        : row.mucosaOral?.descricao;
      return concatNomelocal(face,local);
    },
  },
  orcamento?.id && {
    Header: "Status",
    align: "start",
    getValue: (row) => {
      const { status, checked } = row;

      return checked && <ReactSelectStyled
        value={status}
        onChange={(e) => handleStatus(e, row)}
        options={statusOptions}
      />
    },
    style: tableStatusStyle,
  },
  {
    Header: "Valor",
    align: "start",
    getValue: (row) => {
      const { valorUnitario, checked } = row;
      return <InputMaskCurrency
        id="inputValor"
        value={valorUnitario || ""}
        onChange={(e) => handleValor(e.target.value, row)}
        disabled={!checked || isDisabled}
        error={checked && !valorUnitario && errors.valor}
      />
    },
  },
];

export const pageableDefault = {
  pageNumber: 0,
  pageSize: 10,
  sortDir: "DESC",
  sortField: "dataHoraLancamento",
};

const ReactSelectStyled = styled(ReactSelect)`
  width: 100%;
`