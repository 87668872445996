import Api from "../config/api";

export const findAllStatusOrcamentoOdontologico = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($tipo: TipoStatusOrcamentoOdontologico, $ativo: Boolean, $search: String, $pageableDTO: PageableDTOInput) {
        findAllStatusOrcamentoOdontologico(tipo: $tipo, ativo: $ativo, search: $search, pageableDTO: $pageableDTO) {
          last
          content {
            ativo
            id
            nome
            tipo
          }
        }
      }
    `,
    variables: variables,
  });

  if (response?.data?.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllStatusOrcamentoOdontologico;
};

export const saveStatusOrcamento = async (variables) => {
  const nomeMutation = variables.statusOrcamentoOdontologico.id
    ? "updateStatusOrcamentoOdontologico"
    : "createStatusOrcamentoOdontologico";

  const response = await Api.post("", {
    query: `
      mutation ($statusOrcamentoOdontologico: StatusOrcamentoOdontologicoInput) {
        ${nomeMutation}(statusOrcamentoOdontologico: $statusOrcamentoOdontologico) {
          id
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0].message;
  }
  return response.data.data[nomeMutation];
};

export const findStatusOrcamentoOdontologicoById = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($statusOrcamentoOdontologicoId: UUID) {
        findStatusOrcamentoOdontologicoById(statusOrcamentoOdontologicoId: $statusOrcamentoOdontologicoId) {
          ativo
          id
          nome
          tipo
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.findStatusOrcamentoOdontologicoById;
};

export const inativarStatusOrcamentoOdontologico = async (id) => {
  const response = await Api.post("", {
    query: `
    mutation($id: UUID){
      inativarStatusOrcamentoOdontologico(id: $id) {
        id
      }
    }
    `,
    variables: { id },
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.inativarStatusOrcamentoOdontologico;
};

export const ativarStatusOrcamentoOdontologico = async (id) => {
  const response = await Api.post("", {
    query: `
    mutation($id: UUID){
      ativarStatusOrcamentoOdontologico(id: $id) {
        id
      }
    }
    `,
    variables: { id },
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.ativarStatusOrcamentoOdontologico;
};

export const findAllStatusOrcamentoOdontologicoSelect = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($tipo: TipoStatusOrcamentoOdontologico, $ativo: Boolean, $search: String, $pageableDTO: PageableDTOInput) {
        findAllStatusOrcamentoOdontologico(tipo: $tipo, ativo: $ativo, search: $search, pageableDTO: $pageableDTO) {
          last
          content {
            id
            nome
            tipo
          }
        }
      }
    `,
    variables: variables,
  });

  if (response?.data?.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllStatusOrcamentoOdontologico;
};

export const getStatusOrcamentoPendenteAprovacao = async () => {
  const response = await Api.post("", {
    query: `
      query ($tipo: TipoStatusOrcamentoOdontologico, $ativo: Boolean, $search: String, $pageableDTO: PageableDTOInput) {
        findAllStatusOrcamentoOdontologico(tipo: $tipo, ativo: $ativo, search: $search, pageableDTO: $pageableDTO) {
          content {
            id
            nome
            tipo
          }
        }
      }
    `,
    variables: {
      tipo: "PENDENTE_APROVACAO",
      ativo: true,
      pageableDTO: {
        pageNumber: 0,
        pageSize: 10,
        sortDir: "ASC",
        sortField: "nome",
      }
    },
  });

  if (response?.data?.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllStatusOrcamentoOdontologico.content;
};