import Api from '../config/api'

export const findAllDocumentoClienteAssinatura = async(variables) => {
    const response = await Api.post('', {
        query: `
        query FindAllDocumentoClienteAssinatura($sujeitoAtencao: SujeitoAtencaoInput, $pageableDto: PageableDTOInput, $search: String , $agendamentoId: Long) {
  findAllDocumentoClienteAssinatura(sujeitoAtencao: $sujeitoAtencao, pageableDTO: $pageableDto, search: $search, agendamentoId:$agendamentoId) {  
  content {
      id
      nome
      procedimentos {
        nome
        id
      }
      tipo
      situacao
      sujeitoAtencao {
        id
        nome
      }
      urlDownload
      agendamento {
        id
      }
      profissionalSaude {
        nome
        id
      }
      tituloMensagem
      destinatario
      chaveAssinatura
      chaveDestinatarioAssinou
      chaveDestinatarioCancelou
      chaveS3
      dataHoraValidade
    }
    last
  }
}
      `,
        variables: variables
    });
    if (response?.data?.errors) {
      throw response?.data?.errors[0];
    } else {
      return response.data.data.findAllDocumentoClienteAssinatura

    }
}

export const findContratoById = (variables) => {
    const response = Api.post('', {
        query: `
        query($contratoId: UUID) {
          findContratoById(contratoId: $contratoId) {
            content {
              id
              nome
              
            }
            last
          }
        }
      `,
        variables: variables
    });
    if (!response.data.errors) {
        return response.data.data.findContratoById;
    } else {
        throw response.data.errors[0];
    }
}

export const createContrato = async (variables) => {
    const response = await Api.post("", {
        query: `
      mutation($contrato: Contrato, $objetoAmazonS3DTO: ObjetoAmazonS3DTO ) {
        createContrato(contrato: $contrato, objetoAmazonS3DTO : $objetoAmazonS3DTO) {
          id
        }
      }
      `,
        variables: variables,
    });
    if (!response.data.errors) {
        return response.data.data.createContrato;
    } else {
        throw response.data.errors[0];
    }
}
export const assinarDocumentoPlugssign = async (variables) => {
  const response = await Api.post("", {
      query: `
   mutation AssinarDocumentoPlugsign($dataVencimento: LocalDate, $destinatarios: [String],
  $tituloMsg: String, $assinarDocumentoDto: AssinarDocumentoDTOInput, $camposEntrada: [DocumentoModeloCampoEntradaInput]) {
  assinarDocumentoPlugsign(dataVencimento: $dataVencimento, destinatarios: $destinatarios, 
  tituloMsg: $tituloMsg, assinarDocumentoDTO: $assinarDocumentoDto, camposEntrada: $camposEntrada) {
    urlDownload
    unidade {
      id
    }
    situacao
    tipo
    sujeitoAtencao {
      nome
      id
    }
    chaveAssinatura
    chaveDestinatarioAssinou
    chaveDestinatarioCancelou
    dataHoraAssinatura
    dataHoraCancelamento
    dataHoraCriacao
    dataHoraSolicitacaoAssinatura
    dataHoraValidade
    id
    profissionalSaude {
      id
      nome
    }
    procedimentos {
      id
      nome
    }
  }
}
    `,
      variables: variables,
  });
  if (!response.data.errors) {
      return response.data.data.assinarDocumentoPlugssign;
  } else {
      throw response.data.errors[0];
  }
}
export const updateContrato = async (variables) => {
    const response = await Api.post("", {
        query: `
      mutation($contrato: Contrato) {
        updateContrato(contrato: $contrato) {
          id
        }
      }
      `,
        variables: variables,
    });
    if (!response.data.errors) {
        return response.data.data.updateContrato;
    } else {
        throw response.data.errors[0];
    }
}

export const findAllDocumentoTipo = async (variables) => {
  const response = await Api.post("", {
    query: `query FindAllDocumentoTipo($pageableDto: PageableDTOInput, $descricao: String) {
    findAllDocumentoTipo(pageableDTO: $pageableDto, descricao: $descricao) {
      descricao
      id
    }
  }
  `,variables: variables ,
  });
  if (response.data.errors) {
    throw response.data.errors[0];
  } else {
    return response.data.data.findAllDocumentoTipo;
  }
}
export const findAllDocumentosModelo = async(variables)=>{
  const response = await Api.post("",{
    query:` query FindAllDocumentosModelo($profissionalSaudeId: Long, $ativo: Boolean, $search: String, $pageableDto: PageableDTOInput, $tipo: TipoDocumentoModelo) {
          findAllDocumentosModelo(profissionalSaudeId: $profissionalSaudeId, ativo: $ativo, search: $search, pageableDTO: $pageableDto,tipo: $tipo) {
              content {
                  camposEntrada{
                  campo
                  campoAmigavel
                  campoCompleto
                  valor
                  }
                  camposSistema{
                  campo
                  descricao
                  entidade
                  entidadeComCampo
                  entidadeEnum
                  }
                  modelo
                  name: nome
                  value: id
                  label: nome
              }
          }
        }`,variables:variables
  })
  if(response?.data?.errors){
    throw  response?.data?.errors[0]
  }else{
    return response.data.data.findAllDocumentosModelo.content
  }
}

export const cancelarDocumentoClienteAssinatura= async(documentoClienteAssinaturaId)=>{
  const response = await Api.post("",{
    query:`mutation CancelarDocumentoClienteAssinatura($documentoClienteAssinaturaId: UUID) {
  cancelarDocumentoClienteAssinatura(documentoClienteAssinaturaId: $documentoClienteAssinaturaId) {
    id
    nome
    situacao
  }
}`, variables:{documentoClienteAssinaturaId}
  })
  if(response?.data?.errors){
    throw response?.data?.errors[0]
  }else{
    return response?.data?.data?.cancelarDocumentoClienteAssinatura
  }
}