import Api from "../config/api";

export const getOdontogramaIdBySujeitoAtencao = async (sujeitoAtencaoId) => {
  const response = await Api.post("", {
    query: `
    query ($sujeitoAtencaoId: UUID) {
      findOdontogramaBySujeitoAtencao(sujeitoAtencaoId: $sujeitoAtencaoId) {
      id
      }
    }`,
    variables: { sujeitoAtencaoId },
  });

  if (!response.data.errors) {
    return response.data.data.findOdontogramaBySujeitoAtencao;
  } else {
    throw response.data.errors[0];
  }
};

export const findOdontogramaBySujeitoAtencao = async (sujeitoAtencaoId) => {
  const response = await Api.post("", {
    query: `
      query ($sujeitoAtencaoId: UUID) {
        findOdontogramaBySujeitoAtencao(sujeitoAtencaoId: $sujeitoAtencaoId) {
          id
          denteDeciduo {
            id
            codigo
            observacao
            status
            procedimentoOdontologico {
              id
              observacao
              realizado
              procedimento {
                id
                nome
              }
              agendamento {
                urgente
              }
            }
          }
          dentePermanente {
            id
            codigo
            observacao
            status
            procedimentoOdontologico {
              id
              observacao
              realizado
              procedimento {
                id
                nome
              }
              agendamento {
                urgente
              }
            }
          }
          mucosaOral {
            id
            descricao
            localOdontograma {
              id
            }
            observacao
            procedimentoOdontologico {
              id
              observacao
              realizado
              procedimento {
                id
                nome
              }
              agendamento {
                urgente
              }
            }
          }
          denteSupranumerario {
            id
            codigo
            observacao
            status
            local
            procedimentoOdontologico {
              id
              observacao
              realizado
              procedimento {
                id
                nome
              }
              agendamento {
                urgente
              }
            }
          }
        }
      }      
      `,
    variables: { sujeitoAtencaoId },
  });

  if (!response.data.errors) {
    return response.data.data.findOdontogramaBySujeitoAtencao;
  } else {
    throw response.data.errors[0];
  }
};

export const findAllProcedimentosOdontologico = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($ativo: Boolean, $tiposProcedimentos: [TipoProcedimento], $nome: String, $pageableDTO: PageableDTOInput, $localOdontograma: LocalOdontogramaInput) {
      findAllProcedimentos(ativo: $ativo, tiposProcedimentos: $tiposProcedimentos, nome: $nome, pageableDTO: $pageableDTO, localOdontograma: $localOdontograma) {
        last  
        content {
          id
          nome
          tiposProcedimentos
          tuss22ProcedimentoEventoSaude {
            id
            codigoTermoDescricao
            localOdontograma {
              id
              tipo
            }
          }
        }
      }
    }
    `,
    variables: {
      tiposProcedimentos: [
        'ODONTOLOGICO',
      ],
      ativo: true,
      ...variables,
    },
  });

  if (!response.data.errors) {
    return response.data.data.findAllProcedimentos;
  } else {
    throw response.data.errors[0];
  }
};

export const findAllLocalOdontograma = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($odontogramaId: UUID, $localOdontograma: [Long]) {
      findAllLocalOdontograma(odontogramaId: $odontogramaId, localOdontograma: $localOdontograma) {
        id
        codigo
        descricao
        local
      }
    }
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.findAllLocalOdontograma;
  } else {
    throw response.data.errors[0];
  }
};

export const createProcedimentoOdontologico = async (variables) => {
  const response = await Api.post("", {
    query: `
    mutation ($odontogramaId: UUID, $procedimentoOdontologico: ProcedimentoOdontologicoInput) {
      createProcedimentoOdontologico(odontogramaId: $odontogramaId, procedimentoOdontologico: $procedimentoOdontologico) {
        id
      }
    }
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.createProcedimentoOdontologico;
  } else {
    throw response.data.errors[0];
  }
};

export const findAllProcedimentoOdontologico = async (variables) => {
  const response = await Api.post("", {
    query: `
    query findAllProcedimentoOdontologico($pageableDTO: PageableDTOInput, $odontogramaId: UUID, $orcado: Boolean, $procedimentoRealizado: Boolean, $orcamentoStatus: OrcamentoStatus) {
      findAllProcedimentoOdontologico(odontogramaId: $odontogramaId, pageableDTO: $pageableDTO, orcado: $orcado, procedimentoRealizado: $procedimentoRealizado, orcamentoStatus: $orcamentoStatus) {
        id
        face
        status
        realizado
        procedimento {
          id
          nome
          descricao
        }
        agendamento {
          id
          data
          situacao
          profissionalSaude {
            id
            nome
          }
          procedimentos {
            id
            realizado
          }
        }
        observacao
        dente {
          id
          codigo
          local
        }
        mucosaOral {
          id
          descricao
        }
        orcamentoAtual {
          status
          faturado
          numero
          statusOrcamentoOdontologico {
            id
            nome
            tipo
          }
        }
      }
    }    
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.findAllProcedimentoOdontologico;
  } else {
    throw response.data.errors[0];
  }
};

export const findByProcedimentoOdontologicoId = async (
  procedimentoOdontologicoId
) => {
  const response = await Api.post("", {
    query: `
    query ($procedimentoOdontologicoId: UUID) {
      findByProcedimentoOdontologicoId(procedimentoOdontologicoId: $procedimentoOdontologicoId) {
        id
        observacao
        agendamentoProcedimentoId
        agendamento {
          id
        }
        procedimento {
          id
          nome
        }
        tuss22ProcedimentoEventoSaude {
          id
        }
        face
        dente {
          id
          codigo
          localOdontograma {
            id
            tipo
          }
        }
        mucosaOral {
          descricao
          id
          localOdontograma {
            id
            tipo
          }
        }
      }
    }    
    `,
    variables: { procedimentoOdontologicoId },
  });

  if (!response.data.errors) {
    return response.data.data.findByProcedimentoOdontologicoId;
  } else {
    throw response.data.errors[0];
  }
};

export const updateProcedimentoOdontologico = async (variables) => {
  const response = await Api.post("", {
    query: `
    mutation ($odontogramaId: UUID, $procedimentoOdontologico: ProcedimentoOdontologicoInput) {
      updateProcedimentoOdontologico(odontogramaId: $odontogramaId, procedimentoOdontologico: $procedimentoOdontologico) {
        id
      }
    }
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.updateProcedimentoOdontologico;
  } else {
    throw response.data.errors[0];
  }
};

export const inativarProcedimentoOdontologico = async (
  procedimentoOdontologicoId
) => {
  const response = await Api.post("", {
    query: `
    mutation ($procedimentoOdontologicoId: UUID) {
      inativarProcedimentoOdontologico(procedimentoOdontologicoId: $procedimentoOdontologicoId)
    }    
    `,
    variables: { procedimentoOdontologicoId },
  });

  if (!response.data.errors) {
    return response.data.data.inativarProcedimentoOdontologico;
  } else {
    throw response.data.errors[0];
  }
};

export const alterarStatusDente = async (variables) => {
  const response = await Api.post("", {
    query: `
    mutation ($odontogramaId: UUID, $dente: [DenteInput]) {
      alterarStatusDente(odontogramaId: $odontogramaId, dente: $dente)
    }`,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.alterarStatusDente;
  } else {
    throw response.data.errors[0];
  }
};

export const getListaProcedimentoOdontologico = async (variables) => {
  const response = await Api.post("", {
    query: `
	  query findAllProcedimentoOdontologico($pageableDTO: PageableDTOInput, $odontogramaId: UUID, $agendado: Boolean, $procedimentoRealizado: Boolean) {
		findAllProcedimentoOdontologico(odontogramaId: $odontogramaId, pageableDTO: $pageableDTO, agendado: $agendado, procedimentoRealizado: $procedimentoRealizado) {
		  id
		  procedimento {
        id
        nome
		  }
		  }
	  }    
	  `,
    variables: variables
  });

  if (!response.data.errors) {
    return response.data.data.findAllProcedimentoOdontologico;
  } else {
    throw Error("Erro ao carregar a lista de procedimentos solicitados");
  }
};

export const adicionarObservacaoDente = async(variables) => {
  const response = await Api.post("", {
    query: `
    mutation adicionarObservacaoDente($observacao: String, $odontogramaId: UUID, $denteId: UUID) {
      adicionarObservacaoDente(observacao: $observacao, odontogramaId: $odontogramaId, denteId: $denteId) {
        id
      }
    }
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.adicionarObservacaoDente;
  } else {
    throw response.data.errors[0];
  }
};

export const adicionarObservacaoMucosaOral = async(variables) => {
  const response = await Api.post("", {
    query: `
    mutation adicionarObservacaoMucosaOral($observacao: String, $odontogramaId: UUID, $mucosaOralId: UUID) {
      adicionarObservacaoMucosaOral(observacao: $observacao, odontogramaId: $odontogramaId, mucosaOralId: $mucosaOralId) {
        id
      }
    }
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.adicionarObservacaoMucosaOral;
  } else {
    throw response.data.errors[0];
  }
};

export const createDente = async(variables) => {
  const response = await Api.post("", {
    query: `
    mutation ($sujeitoAtencaoId: UUID, $odontogramaId: UUID, $dente: [DenteInput]) {
      createDente(sujeitoAtencaoId: $sujeitoAtencaoId, odontogramaId: $odontogramaId, dente: $dente) {
        id
      }
    }
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.createDente;
  } else {
    throw response.data.errors[0];
  }
};

export const findByDente = async(variables) => {
  const response = await Api.post("", {
    query: `
	  query ($odontogramaId: UUID, $denteId: UUID) {
      findByDente(odontogramaId: $odontogramaId, denteId: $denteId) {
        id
        procedimentoOdontologicoRealizado {
          id
          realizado
          dataHoraLancamento
          procedimento {
            nome
          }
          procedimentoRealizado {
            dataHoraLancamento
            profissionalSaude {
              nome
              usuario {
                fotoPerfil
              }
            }
          }
        }
      }
    }`,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.findByDente;
  } else {
    throw Error("Erro ao carregar o histórico do dente.");
  }
};

export const findByMucosaOral = async(variables) => {
  const response = await Api.post("", {
    query: `
	  query ($odontogramaId: UUID, $mucosaOralId: UUID) {
      findByMucosaOral(odontogramaId: $odontogramaId, mucosaOralId: $mucosaOralId) {
        id
        procedimentoOdontologico {
          id
          realizado
          procedimento {
            nome
          }
          procedimentoRealizado {
            dataHoraLancamento
            profissionalSaude {
              nome
              usuario {
                fotoPerfil
              }
            }
          }
        }
      }
    }`,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.findByMucosaOral;
  } else {
    throw Error("Erro ao carregar o histórico da mucosa oral.");
  }
};

export const findAllConvenio = async(searchDTO) => {
  const response = await Api.post("", {
    query: `
      query ($searchDTO: SearchDTOInput) {
          findAllConvenio(searchDTO: $searchDTO) {
              last
              content {
                id
                descricao
                value:id
                label:descricao
              }
          }
      }
  `,
    variables: {searchDTO},
  });

  if (!response.data.errors) {
    return response.data.data.findAllConvenio;
  }

}

export const findAllProcedimentoOdontologicoSemOrcamento = async (variables) => {
  const response = await Api.post("", {
    query: `
    query findAllProcedimentoOdontologico($sujeitoAtencaoId: UUID, $pageableDTO: PageableDTOInput, $odontogramaId: UUID, $orcado: Boolean, $procedimentoRealizado: Boolean) {
      findAllProcedimentoOdontologico(sujeitoAtencaoId: $sujeitoAtencaoId, odontogramaId: $odontogramaId, pageableDTO: $pageableDTO, orcado: $orcado, procedimentoRealizado: $procedimentoRealizado) {
        id
        face
        status
        procedimento {
          id
          nome
          descricao
          convenioProcedimentos {
            convenio {
              id
            }
            valor
          }
        }
        dente {
          id
          codigo
        }
        mucosaOral {
          id
          descricao
        }
      }
    }    
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.findAllProcedimentoOdontologico;
  } else {
    throw response.data.errors[0];
  }
};

export const saveOrcamento = async(orcamento) => {
  const method = orcamento?.id ? "updateOrcamento" : "createOrcamento";

  const response = await Api.post("", {
    query: `
      mutation($orcamento: OrcamentoInput) {
        ${method}(orcamento: $orcamento) {
          id
          status
          statusOrcamentoOdontologico {
            id
            tipo
            nome
          }
        }
      }
    `,
    variables: {orcamento},
  });

  if (!response.data.errors) {
    return response.data.data[method];
  } else {
    throw response.data.errors[0];
  }
}

export const findOrcamentoById = async(id) =>{
  const response = await Api.post("", {
    query: `
    query($orcamentoId: UUID) {
      findOrcamentoById(orcamentoId: $orcamentoId) {
        convenio {
          label:descricao
          value:id
          id
          descricao
        }
        dataVencimento
        id
        nome
        observacao
        status
        statusOrcamentoOdontologico {
          id
          nome
          tipo
        }
        agendamento {
          id
        }
        desconto {
          tipoDesconto
          valorDesconto
        }
        orcamentoProcedimentos {
            id
            espessura
            procedimento {
              id
              nome
              descricao
              convenioProcedimentos {
                valor
                convenio {
                  id
                }
              }
            }
           procedimentoOdontologico {
              id
              status
              dente {
                id
                codigo
              }
              mucosaOral {
                id
                descricao
              }
              procedimento {
                id
                nome
                convenioProcedimentos {
                  convenio {
                    id
                  }
                  valor
                }
              }
            }
            valorUnitario
            quantidade
          }
        sujeitoAtencao {
          id
          nome
        }
        profissionalSaude {
          id
          nome
        }
        tipo
        tipoPagamento
        totalParcelas
        valor
        valorEntrada
        numero
        historico {
          dataHoraLancamento
          informacao
          profissionalSaude {
            nome
          }
        }
      }
    }    
    `, variables: {orcamentoId: id},
  });

  if (!response.data.errors) {
    return response.data.data.findOrcamentoById;
  } else {
    throw response.data.errors[0];
  }
}

export const findConfiguracaoUnidade = async() => {
  const response = await Api.post("", {
    query: `
    query {
      findByUnidadeLogadaConfiguracaoUnidade{
        limiteDescontoOrcamento
      }
    }`,
  });

  if (!response.data.errors) {
    return response.data.data.findByUnidadeLogadaConfiguracaoUnidade;
  }
};

export const adicionarAgendamentoProcedimento = async(variables) => {
  const response = await Api.post("", {
    query: `
    mutation AdicionarAgendamentoProcedimento($agendamento: AgendamentoInput, $agendamentoProcedimento: AgendamentoProcedimentoInput) {
      adicionarAgendamentoProcedimento(agendamento: $agendamento, agendamentoProcedimento: $agendamentoProcedimento) {
        id
      }
    }`, variables : variables
  });

  if (!response.data.errors) {
    return response.data.data.adicionarAgendamentoProcedimento;
  }
};

export const findOrcamentoByIdGerenciador = async(id) =>{
  const response = await Api.post("", {
    query: `
    query($orcamentoId: UUID) {
      findOrcamentoById(orcamentoId: $orcamentoId) {
        convenio {
          label:descricao
          value:id
          id
          descricao
        }
        dataVencimento
        id
        nome
        observacao
        status
        statusOrcamentoOdontologico {
          id
          nome
          tipo
        }
        agendamento {
          id
        }
        desconto {
          tipoDesconto
          valorDesconto
        }
        orcamentoProcedimentos {
            id
            espessura
            procedimento {
              id
              nome
              descricao
              convenioProcedimentos {
                valor
                convenio {
                  id
                }
              }
            }
           procedimentoOdontologico {
              id
              status
              dente {
                id
                codigo
              }
              mucosaOral {
                id
                descricao
              }
              procedimento {
                id
                nome
                convenioProcedimentos {
                  convenio {
                    id
                  }
                  valor
                }
              }
            }
            valorUnitario
            quantidade
          }
        sujeitoAtencao {
          id
          nome
          dadosConvenio {
            convenio {
              id
              descricao
              convenioProcedimentos {
                procedimento {
                  id
                }
                valor
              }
            }
          }
        }
        profissionalSaude {
          id
          nome
        }
        tipo
        tipoPagamento
        totalParcelas
        valor
        valorEntrada
        numero
        historico {
          dataHoraLancamento
          informacao
          profissionalSaude {
            nome
          }
        }
      }
    }    
    `, variables: {orcamentoId: id},
  });

  if (!response.data.errors) {
    return response.data.data.findOrcamentoById;
  } else {
    throw response.data.errors[0];
  }
}