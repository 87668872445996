import React from "react";
import { Switch } from "@material-ui/core";

export const notificationDefault = {
  open: false,
  message: "",
  type: "",
};

export const pageableDTODefault = {
  pageNumber: 0,
  pageSize: 30,
  sortDir: "ASC",
  sortField: "nome",
};

export const tipoStatusOrcamentoOdontologico = {
  "APROVADO": "Aprovado",
  "PENDENTE_APROVACAO": "Pendente",
  "REPROVADO": "Reprovado",
};

export const columns = ({ functions }) => [
  {
    Header: "Nome",
    getValue: (statusOrcamento) => statusOrcamento.nome,
    props: { component: "th", scope: "row" },
    field: "nome",
  },
  {
    Header: "Tipo",
    getValue: (statusOrcamento) => {
      const tipo = statusOrcamento?.tipo ? tipoStatusOrcamentoOdontologico[statusOrcamento.tipo] : "";

      return tipo;
    },
    props: { component: "th", scope: "row" },
    field: "tipo",
  },
  {
    Header: "Status",
    getValue: (statusOrcamento) => {
      return <Switch checked={statusOrcamento.ativo} color="primary" />;
    },
    props: { component: "th", scope: "row" },
  },
];

export const statusDefault = {
  nome: "",
  ativo: true,
  tipo: null,
};

export const tipos = [
  {
    value: "PENDENTE_APROVACAO",
    label: "Pendente",
  },
  {
    value: "APROVADO",
    label: "Aprovado",
  },
  {
    value: "REPROVADO",
    label: "Reprovado",
  },
  {
    value: "FINALIZADO",
    label: "Finalizado",
  },
  {
    value: "CANCELADO",
    label: "Cancelado",
  },
];
