import { withStyles } from "@material-ui/core";
import moment from "moment";
import React from "react";
import Table from "../../../../../../components/Table/Table";
import { columnsModal } from "../../constants";

const TabOrcamento = ({ classes, orcamentoSelected }) => {
  const {
    numero,
    procedimento,
    observacao,
    statusOrcamentoOdontologico,
    dataHoraLancamento,
    procedimentosOdontologicos,
  } = orcamentoSelected;
  const data = moment(dataHoraLancamento).format("DD/MM/YYYY HH:mm");

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.row}>
          <div className={classes.field}>
            <label> Nº </label>
            {numero}
          </div>
          <div className={classes.field}>
            <label> Lançamento </label>
            {data}
          </div>
          <div className={classes.field}>
            <label> Status </label>
            {statusOrcamentoOdontologico?.nome}
          </div>
        </div>
        <div className={classes.field}>
          <label> Observações </label>
          {observacao}
        </div>
      </div>
      <div>
        <label> Procedimento: {procedimento} </label>
      </div>
      <div className={classes.containerTable}>
        <Table dados={procedimentosOdontologicos} columns={columnsModal} />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",

    "& > label": {
      color: "#505050",
      fontSize: "10px",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "32px",
  },
  field: {
    display: "flex",
    flexDirection: "column",

    "& > label": {
      color: "#505050",
      fontSize: "10px",
    },
  },
  containerTable: {
    display: "flex",
    overflow: "auto",
    height: 'calc(100% - 100px)',
  },
};

export default withStyles(styles)(TabOrcamento);
