import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState, Suspense, lazy } from "react";
import { CircularProgress, withStyles } from "@material-ui/core";
import styles from "./StatusOrcamentoStyle";
import Dialog from "../../../components/Dialog/Dialog";
import CloseIcon from "../../../components/Icon/Close";
import { Button } from "../../../components/ui/Buttons";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import { findAllProfissionalSaudeAuditByProfissionalSaudeId } from "../../../services/AuditoriaService";

const Auditoria = lazy(() => import("../../../components/Auditoria/Auditoria"));
const InformacoesStatusOrcamento = lazy(() =>
  import("./InformacoesStatusOrcamento")
);

const ModalStatusOrcamento = observer(
  ({
    classes,
    auditoriaStore,
    open,
    onClose,
    statusSelecionado,
    openNotification,
    loadStatusOrcamento,
  }) => {
    const [tabSelected, setTabSelected] = useState(0);

    useEffect(() => {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        auditoria: "o status do orçamento",
        auditoriaTela: "statusOrcamento",
      };
    }, []);

    const isEdit = statusSelecionado?.id;
    const getPageTitle = () => {
      const acao = isEdit ? "Editar" : "Novo";

      return pageConfiguracoes(`${acao} status orçamento`);
    };

    const changeTabSelected = async (tab) => {
      if (tab === 1) {
        auditoriaStore.auditoriaProps = {
          ...auditoriaStore.auditoriaProps,
          pageNumber: 0,
          lastPage: false,
          auditorias: [],
        };

        auditoriaStore.load = {
          query: findAllProfissionalSaudeAuditByProfissionalSaudeId,
          variableName: "statusOrcamentoOdontologicoId",
          variableId: statusSelecionado.id,
        };
        await auditoriaStore.loadAuditItems();
      }
      setTabSelected(tab);
    };

    const renderTab = () => {
      return tabSelected === 0 ? (
        <InformacoesStatusOrcamento
          onClose={onClose}
          statusSelecionado={statusSelecionado}
          openNotification={openNotification}
          loadStatusOrcamento={loadStatusOrcamento}
        />
      ) : (
        <Auditoria />
      );
    };

    return (
      <>
        <PageTitle title={getPageTitle()} />
        <Dialog
          open={open}
          onClose={onClose}
          classes={{
            paper: classes.dialogPaper,
          }}
          fullWidth={true}
        >
          <div className={classes.modalHeader}>
            <div className={classes.titleModal}>
              {isEdit ? "Editar status orçamento" : "Novo status orçamento"}
            </div>
            <Button
              shape="circle"
              bgColor="#FB7676"
              style={{ height: 32, width: 32 }}
              onClick={onClose}
            >
              <CloseIcon />
            </Button>
          </div>
          <div className={classes.containerModal}>
            <MultiToggleButtons
              options={["Informações", "Auditoria"]}
              tabSelected={tabSelected}
              changeTabSelected={changeTabSelected}
              classes={{ buttonsContainer: classes.toggleButtons }}
              disabled
              posicaoDesabilitada={[1]}
            />
            <div className={classes.contentModal}>
              <Suspense fallback={<CircularProgress size={32} />}>
                {renderTab()}
              </Suspense>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
);

const ModalStatusOrcamentoWithStyles = withStyles(styles)(ModalStatusOrcamento);
export default inject("auditoriaStore")(ModalStatusOrcamentoWithStyles);
