import { withStyles } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { InputForm } from "../../../../../../../../components/Input/InputDadosCadastraisPaciente";
import { Button } from "../../../../../../../../components/ui/Buttons";
import { NearMe } from "@material-ui/icons";
import { inject } from "mobx-react";
import { addHistoricoOrcamento } from "../../../../../../../../services/OrcamentoService";
import { observer } from "mobx-react-lite";
import { pageableDefault } from "../../Constatnts";
import Scroll from "../../../../../../../../components/InfiniteScroll/Scroll";
import { findAllHistoricoOrcamentoOdontologico } from "../../../../../../../../services/GerenciadorOrcamentosService";

const TabHistorico = observer(({ classes, orcamento, sujeitoAtencaoStore }) => {
  const [informacao, setInformacao] = useState("");
  const [pageableDTO, setPageableDTO] = useState(pageableDefault);
  const [last, setLast] = useState(false);
  const [historico, setHistorico] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadHistorico({ isClear: true });
  }, []);

  const loadHistorico = async (option) => {
    const response = await findAllHistoricoOrcamentoOdontologico(orcamento.id);
    const { last, content } = response;

    setHistorico(option.isClear ? content : [...historico, ...content]);
    setLast(last);
    setPageableDTO({
      ...pageableDTO,
      pageNumber: option?.isClear ? 1 : pageableDTO.pageNumber + 1,
    });
  };

  const saveInformacao = async () => {
    try {
      setLoading(true);

      await addHistoricoOrcamento({
        orcamentoId: orcamento.id,
        informacao,
      });

      loadHistorico({ isClear: true });

      sujeitoAtencaoStore.openNotification(
        "Informação salva com sucesso",
        "success"
      );

      setInformacao("");
    } catch (error) {
      sujeitoAtencaoStore.openNotification(
        "Erro ao salvar a informação",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <Scroll
        hasMore={!last}
        pageStart={0}
        initialLoad={false}
        loadMore={loadHistorico}
        className={classes.scrollContainer}
      >
        <div className={classes.containerList}>
          {historico &&
            historico.map((item, index) => {
              const { dataHoraLancamento, informacao, profissionalSaude } =
                item || {};
              const data = moment(dataHoraLancamento).format(
                "DD/MM/YYYY HH:mm"
              );

              return (
                <div key={index} className={classes.itemHistorico}>
                  <div className={classes.itemCriacao}>
                    {profissionalSaude?.nome} - {data}
                  </div>
                  <div className={classes.itemInformacao}>{informacao}</div>
                </div>
              );
            })}
        </div>
      </Scroll>
      <div className={classes.containerInput}>
        <InputForm
          value={informacao}
          className={classes.inputInformacao}
          rows={4}
          multiline
          onChange={(e) => setInformacao(e.target.value)}
        />
        <Button
          color="primary"
          shape="circle"
          disabled={!informacao || loading}
          style={{ height: 40, width: 40 }}
          onClick={saveInformacao}
        >
          <NearMe className={classes.buttonSendIcon} />
        </Button>
      </div>
    </div>
  );
});

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    height: "100%",
  },
  scrollContainer: {
    height: "100%",
    "& > div": {
      height: "100%",
    },
  },
  containerList: {
    overflowY: "auto",
    height: "calc(100% - 18px)",
    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  itemHistorico: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  itemCriacao: {
    fontSize: "12px",
    fontWeight: "600",
  },
  inputInformacao: {
    background: "#F2F2F2",
    padding: "0 10px",
    borderRadius: 8,
    width: "93%",
    "& textArea": {
      padding: 0,
    },
  },
  containerInput: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
  },
  buttonSendIcon: {
    color: "white",
    width: 20,
    height: 20,
  },
};

const TabHistoricoWithStyles = withStyles(styles)(TabHistorico);
export default inject("sujeitoAtencaoStore")(TabHistoricoWithStyles);
