import React from "react";
import moment from "moment";

const GerenciadorOrcamentosRelatorio = ({ dadosRelatorio, filters }) => {
  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: "#F2F2F2" };
    }
    return "";
  };

  const {
    statusOrcamentoOdontologico,
    numero,
    sujeitoAtencao,
    dataHoraLancamentoInicio,
    dataAgendamento,
    profissionalSaude,
  } = filters || {};

  return (
    <>
      <div style={styles.titulo}>Relatório de Orçamentos</div>
      <div style={styles.subTitulo}>
        {statusOrcamentoOdontologico?.id && (
          <div>
            Status:
            <span>{statusOrcamentoOdontologico?.nome}</span>
          </div>
        )}
        {numero && (
          <div>
            Nº:
            <span>{numero}</span>
          </div>
        )}
        {sujeitoAtencao?.id && (
          <div>
            Sujeito Atenção:
            <span>{sujeitoAtencao?.nome}</span>
          </div>
        )}
        {dataHoraLancamentoInicio && (
          <div>
            Data lançamento:
            <span>{moment(dataHoraLancamentoInicio).format("DD/MM/YYYY")}</span>
          </div>
        )}
        {dataAgendamento && (
          <div>
            Data lançamento:
            <span>{moment(dataAgendamento).format("DD/MM/YYYY")}</span>
          </div>
        )}
        {profissionalSaude && (
          <div>
            Profissional:
            <span>{profissionalSaude?.nome}</span>
          </div>
        )}
      </div>
      <div style={styles.headerColumn}>
        <div style={styles.column}>Nº</div>
        <div style={styles.column}>Nome do Paciente</div>
        <div style={styles.column}>Data agendamento</div>
        <div style={styles.column}>Data lançamento</div>
        <div style={styles.column}>Profissional</div>
        <div style={styles.column}>Status</div>
        <div style={styles.column}>Último registro</div>
      </div>
      <div>
        {dadosRelatorio.map((item, index) => {
          const {
            numero,
            sujeitoAtencao,
            agendamento,
            dataHoraLancamento,
            profissionalSaude,
            status,
            historico,
          } = item || {};

          const ultimoRegistro = historico?.length > 0 && historico[0];

          return (
            <div
              key={item.id}
              style={{ ...styles.containerColuna, ...columnColor(index) }}
            >
              <div style={styles.column}>{numero}</div>
              <div style={styles.column}>{sujeitoAtencao?.nome || ""}</div>
              <div style={styles.column}>
                {agendamento?.id
                  ? moment(agendamento?.data).format("DD/MM/YYYY")
                  : ""}
              </div>
              <div style={styles.column}>
                {moment(dataHoraLancamento).format("DD/MM/YYYY HH:mm")}
              </div>
              <div style={styles.column}>{profissionalSaude.nome}</div>
              <div style={styles.column}>{status}</div>
              <div style={styles.column}>
                {ultimoRegistro ? ultimoRegistro.informacao : ""}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  titulo: {
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
    fontWeight: 700,
  },
  subTitulo: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #505050",
    marginBottom: "15px",
    paddingBottom: "5px",
    fontWeight: 700,
    justifyContent: "center",
    gap: "8px",
  },

  headerColumn: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    width: "100%",
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  column: {
    width: "100%",
  },
};

export default GerenciadorOrcamentosRelatorio;
