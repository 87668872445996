import React, { useEffect, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import ItemSubMenu from './ItemSubMenu'
import { isEmpty } from '../../../../utils/checkObj'
import { inject } from 'mobx-react'
import { checkUserRole } from '../../../../utils/checkUserRole'
import styled from 'styled-components'
import { getMenuItems } from './utils/getMenuItems'
import { observer } from 'mobx-react-lite'
import { findByUnidadeLogadaConfiguracaoUnidade } from '../../../../services/ConfiguracoesUnidadeService'
import { getConfiguracaoUnidade } from '../../../../services/DocumentoService'

const TitleMenu = styled.div`
  font-weight: 700;
  font-size: 10px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #505050;
  padding-left: 30px;
  margin-bottom: 4px;
`

const SujeitoAtencaoSubMenu = observer(({ match, sujeitoAtencaoStore, location }) => {
  const [selected, setSelected] = useState('principal')
  const [utilizaPlugsign, setUtilizaPlugsign] = useState(false)
  const [isTituloParcelaRead, setIsTituloParcelaRead] = useState(false)

  const profissionalLogado = JSON.parse(localStorage[`_immortal|profissional_saude_logado`] || null)
  const isEspecialidadeOdontologia = profissionalLogado.especialidades.some(item => item.especialidade === "Odontologia");

  const items = getMenuItems({
    match,
    sujeitoAtencaoStore,
    isTituloParcelaRead,
    profissionalLogado,
    utilizaPlugsign,
    isEspecialidadeOdontologia,
  })

  const verifyIsTituloParcelaRead = async () => {
    const isTituloParcelaRead = await checkUserRole('ROLE_TITULO_PARCELA_READ')
    setIsTituloParcelaRead(isTituloParcelaRead)
  }

  const verifyUseSessionControl = async () => {
    const response = await findByUnidadeLogadaConfiguracaoUnidade();
    const { utilizaControleSessao } = response.data.data.findByUnidadeLogadaConfiguracaoUnidade
    sujeitoAtencaoStore.withSessionControl = utilizaControleSessao;
    return utilizaControleSessao
  }

  const handleSelected = selected => {
    setSelected(selected)
  }

  const verificaConfigUnidade = async () => {
    const configuracaoUnidade = await getConfiguracaoUnidade();
    setUtilizaPlugsign(configuracaoUnidade.utilizaPlugsign);
  };

  useEffect(() => {
    let checkSelected = window.location.href.split('/')[7]
    verifyIsTituloParcelaRead()
    verifyUseSessionControl()
    if (isEmpty(match.params)) {
      checkSelected = window.location.href.split('/')[5]
    }
    const selectionOptions = {
      undefined: 'principal',
      prontuario: 'prontuario',
      log: 'log',
      consultas: 'consultas',
      'procedimentos-realizados': 'procedimentos-realizados',
      pagamentos: 'pagamentos',
      auditoria: 'auditoria',
      arquivos: 'arquivos',
      'sessoes-em-andamento': 'sessoes-em-andamento',
      orcamentos: 'orcamentos',
      documentos:'documentos',
      'procedimentos-odontologicos': 'procedimentos-odontologicos',
    }

    const selected = selectionOptions[checkSelected]
    if (selected) {
      setSelected(selected)
    }

    verificaConfigUnidade();
  }, [])

  return (
    <Grid container direction={'column'} wrap={'nowrap'}>
      {items.map(item => (
        <>
          <TitleMenu title={item.title}> {item.title} </TitleMenu>
          {item?.children?.map(route => {
            if (isEmpty(match.params) && (route.id !== 'prontuario' || route.id !== 'consultas'))
              return null
            if (route.hidden) return null
            return (
              <ItemSubMenu
                key={route.uri}
                to={route.to}
                id={route.id}
                title={route.name}
                location={location}
                badgeCounter={route.badgeCounter}
                selected={selected === route.id}
                setSelected={handleSelected}
              />
            )
          })}
        </>
      ))}
    </Grid>
  )
})

export default inject('sujeitoAtencaoStore')(SujeitoAtencaoSubMenu)
