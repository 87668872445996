import Api from "../config/api";

export const findAllProcedimentoOdontologicoSujeitoAtencao = async (
  variables
) => {
  const response = await Api.post("", {
    query: `
    query ($orcado: Boolean, $sujeitoAtencaoId: UUID, $procedimentoRealizado: Boolean, $status: [ProcedimentoOdontologicoStatus], $pageableDTO: PageableDTOInput) {
    findAllProcedimentoOdontologico(orcado: $orcado, sujeitoAtencaoId: $sujeitoAtencaoId, procedimentoRealizado: $procedimentoRealizado, status: $status, pageableDTO: $pageableDTO) {
      id
      valor
      status
      orcamentoAtual {
        id
        numero
      }
      agendamento {
        data
        profissionalSaude {
          nome
        }
      }
      procedimento {
        nome
      }
    }
  }`,
    variables,
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllProcedimentoOdontologico;
};

export const findOrcamentoById = async (orcamentoId) => {
  const response = await Api.post("", {
    query: `
    query ($orcamentoId: UUID) {
      findOrcamentoById(orcamentoId: $orcamentoId) {
        id
        numero
        observacao
        faturado
        dataHoraLancamento
        statusOrcamentoOdontologico {
          nome
          id
          tipo
        }
        convenio {
          descricao
          id
        }
        procedimentosOdontologicos {
          procedimento {
            nome
          }
          dente {
            codigo
          }
          face
          mucosaOral {
            descricao
          }
          status
        }
      }
    }`,
    variables: { orcamentoId },
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findOrcamentoById;
};

export const updateStatusProcedimento = async (variables) => {
  const response = await Api.post("", {
    query: `mutation UpdateStatusProcedimentoOdontologico($procedimentosOdontologicoId: [UUID], $status: ProcedimentoOdontologicoStatus) {
    updateStatusProcedimentoOdontologico(procedimentosOdontologicoId: $procedimentosOdontologicoId, status: $status)
  }`,
    variables,
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.UpdateStatusProcedimentoOdontologico;
};

export const findAllAgendamentoByProcedimentoOdontologico = async (
  variables
) => {
  const response = await Api.post("", {
    query: `query ($orcamentoId: UUID, $procedimentoOdontologicoId: UUID) {
      findAllAgendamentoByProcedimentoOdontologico(orcamentoId: $orcamentoId, procedimentoOdontologicoId: $procedimentoOdontologicoId) {
        data
        horaFormatada
        situacao
        profissionalSaude {
          nome
        }
      }
    }`,
    variables,
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.findAllAgendamentoByProcedimentoOdontologico;
};
