import React from 'react';
import styled from "styled-components";
import { situacoes } from '../../../pages/Atendimento/Tabs/Odontograma/Constants';

const ContentStatus  = styled.div`
    background: ${props => situacoes[props.label].backgroundColor}};
    border: 0.5px solid ${props => situacoes[props.label].color}};
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    border-radius: 8px;
    color: #505050;
    font-weight: 600;
    gap: 4px;
    max-width: 194px;
    text-wrap: nowrap;
`;

const CircleColor = styled.div`
    width: 12px;
    height: 12px;
    background: ${props => situacoes[props.label].color};
    border-radius: 18px;
`;

const Situacao = ({ label, faturado, whitelabel }) => {
    const is3dpe = whitelabel?.nome === "_3DPE"
    const textLabel = is3dpe && label === 'APROVADO' ? "APROVADO3DPE" : label;
    const labelSituacao = faturado ? 'FATURADO' : textLabel

    return(<ContentStatus label={labelSituacao}>
        <CircleColor label={labelSituacao}/>
        { situacoes[labelSituacao].label }
    </ContentStatus>)
};

export default Situacao;
