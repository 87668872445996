import { CircularProgress, withStyles } from "@material-ui/core";
import React from "react";
import Scroll from "../../components/InfiniteScroll/Scroll";
import Table from "../../components/Table/Table";
import { columns } from "./constants";
import MensagemListaVazia from "../../components/Mensagem/MensagemListaVazia";

const GerenciadorOrcamentosTable = ({
  classes,
  orcamentos,
  lastScroll,
  loadOrcamentos,
  handleSelectOrcamento,
  loading,
}) => {

  return (
    <Scroll
      loadMore={loadOrcamentos}
      hasMore={!lastScroll}
      pageStart={0}
      initialLoad={false}
    >
      <Table
        dados={orcamentos || []}
        columns={columns}
        clickable
        handleClick={handleSelectOrcamento}
      />
      {loading && (
        <div className={classes.wrapperLoading}>
          <CircularProgress />
        </div>
      )}
      {!loading && orcamentos.length === 0 && <MensagemListaVazia />}
    </Scroll>
  );
};

const styles = {
  wrapperLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default withStyles(styles)(GerenciadorOrcamentosTable);
