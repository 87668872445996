import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const DownaloadIcon = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M19.5553 21.1218C20.0731 21.1218 20.4928 21.5415 20.4928 22.0593C20.4928 22.5339 20.1401 22.9261 19.6825 22.9882L19.5553 22.9968H6.43701C5.91924 22.9968 5.49951 22.577 5.49951 22.0593C5.49951 21.5847 5.8522 21.1924 6.3098 21.1303L6.43701 21.1218H19.5553ZM13.004 2.99902C13.4787 2.99902 13.8709 3.35172 13.933 3.80931L13.9415 3.93652L13.9408 16.6778L17.6485 12.9645C17.9811 12.6314 18.5019 12.6007 18.8691 12.8726L18.9744 12.9633C19.3075 13.2959 19.3382 13.8167 19.0663 14.1839L18.9756 14.2891L13.6721 19.6025L13.5837 19.6806L13.47 19.7538L13.4245 19.7803L13.3105 19.8277L13.1599 19.8653L13.0733 19.8752L12.9983 19.8776C12.9356 19.8776 12.872 19.8707 12.8099 19.8576L12.7088 19.8287C12.5888 19.7899 12.4799 19.7271 12.3872 19.6461L7.02521 14.2902C6.65883 13.9244 6.65841 13.3308 7.02427 12.9644C7.35687 12.6313 7.87767 12.6007 8.24488 12.8728L8.35009 12.9635L12.0658 16.6728L12.0665 3.93652C12.0665 3.41876 12.4863 2.99902 13.004 2.99902Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DownaloadIcon;
